import { GraphQLClient } from "graphql-request"
import * as Dom from "graphql-request/dist/types.dom"
import gql from "graphql-tag"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Json: any
  JSON: any
  Date: any
  URL: any
  EmailAddress: any
  Upload: any
}

export type Query = {
  __typename?: "Query"
  address?: Maybe<Address>
  addresses?: Maybe<Array<Maybe<Address>>>
  admin?: Maybe<User>
  admins?: Maybe<AdminsPayload>
  article?: Maybe<Article>
  articles?: Maybe<ArticlesPayload>
  articlesById?: Maybe<Array<Maybe<Article>>>
  cart?: Maybe<Cart>
  checkAccountExistence?: Maybe<Scalars["Boolean"]>
  choicesArticle?: Maybe<ChoicesArticle>
  choicesArticleCombination?: Maybe<ChoicesArticleCombination>
  choicesArticleCombinations?: Maybe<ChoicesArticleCombinationsPayload>
  choicesArticleCombinationsById?: Maybe<Array<ChoicesArticleCombination>>
  choicesArticles?: Maybe<ChoicesArticlesPayload>
  choicesArticlesById?: Maybe<Array<ChoicesArticle>>
  choicesCart?: Maybe<Cart>
  choicesRelatedCombination?: Maybe<ChoicesRelatedCombination>
  choicesRelatedCombinations?: Maybe<ChoicesRelatedCombinationsPayload>
  files: Array<File>
  filters?: Maybe<FilterPayload>
  form?: Maybe<Form>
  forms: FormsPayload
  formSubmission?: Maybe<FormSubmission>
  formSubmissions: FormSubmissionsPayload
  getMyParcelLabelUrl?: Maybe<Scalars["String"]>
  giftcards?: Maybe<Array<Maybe<Giftcard>>>
  invoice?: Maybe<Invoice>
  invoices?: Maybe<InvoicesPayload>
  lead?: Maybe<Lead>
  leads?: Maybe<LeadsPayload>
  loyaltyStatus?: Maybe<DafLoyaltyStatus>
  me?: Maybe<User>
  order?: Maybe<Order>
  orderByIdAndEmail?: Maybe<Order>
  orderForReturn?: Maybe<Order>
  orders?: Maybe<OrdersPayload>
  originalValueGiftcard?: Maybe<Scalars["Int"]>
  paymentMethods?: Maybe<Scalars["Json"]>
  postalCodeLookup?: Maybe<PostalCodeLookupPayload>
  preferences?: Maybe<Array<Maybe<DafPreferenceTypeItem>>>
  privileges?: Maybe<Array<Maybe<DafPrivilegeTypeItem>>>
  publicWishlist?: Maybe<UserWishlist>
  tourByCodeAndEmail?: Maybe<TourBooking>
  userFile?: Maybe<File>
  userFiles?: Maybe<FilesPayload>
  userFormSubmission?: Maybe<FormSubmission>
  validateCurrentPassword?: Maybe<Scalars["Boolean"]>
  verifyAdyenPayment?: Maybe<Scalars["Boolean"]>
  version: Scalars["String"]
  voucher?: Maybe<Voucher>
  vouchers?: Maybe<VouchersPayload>
  wishlist?: Maybe<UserWishlist>
  wishlists?: Maybe<Array<UserWishlist>>
}

export type QueryAddressArgs = {
  id: Scalars["String"]
}

export type QueryAdminArgs = {
  id: Scalars["String"]
}

export type QueryAdminsArgs = {
  filter?: InputMaybe<QueryUsersWhereInput>
  orderBy?: InputMaybe<QueryUsersOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryArticleArgs = {
  id: Scalars["String"]
}

export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticlesFilterInput>
  pagination?: InputMaybe<ArticlesPaginationInput>
}

export type QueryArticlesByIdArgs = {
  ids: Array<Scalars["String"]>
}

export type QueryCartArgs = {
  reference?: InputMaybe<Scalars["String"]>
}

export type QueryCheckAccountExistenceArgs = {
  email: Scalars["String"]
}

export type QueryChoicesArticleArgs = {
  id: Scalars["String"]
}

export type QueryChoicesArticleCombinationArgs = {
  id: Scalars["String"]
}

export type QueryChoicesArticleCombinationsArgs = {
  filter?: InputMaybe<ChoicesArticleCombinationsFilter>
  pagination?: InputMaybe<ChoicesArticleCombinationsPagination>
}

export type QueryChoicesArticleCombinationsByIdArgs = {
  ids: Array<Scalars["String"]>
}

export type QueryChoicesArticlesArgs = {
  filter: ChoicesArticlesFilter
  pagination?: InputMaybe<ChoicesArticlesPagination>
}

export type QueryChoicesArticlesByIdArgs = {
  ids: Array<Scalars["String"]>
}

export type QueryChoicesCartArgs = {
  reference?: InputMaybe<Scalars["String"]>
}

export type QueryChoicesRelatedCombinationArgs = {
  id: Scalars["String"]
}

export type QueryChoicesRelatedCombinationsArgs = {
  filter?: InputMaybe<ChoicesRelatedCombinationsFilter>
  pagination?: InputMaybe<ChoicesRelatedCombinationsPagination>
}

export type QueryFilesArgs = {
  after?: InputMaybe<FileWhereUniqueInput>
  before?: InputMaybe<FileWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<Array<QueryFilesOrderByInput>>
}

export type QueryFiltersArgs = {
  filters?: InputMaybe<ArticlesFilterInput>
}

export type QueryFormArgs = {
  id: Scalars["String"]
}

export type QueryFormsArgs = {
  filter?: InputMaybe<FormsFilterInput>
  orderBy?: InputMaybe<FormsOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryFormSubmissionArgs = {
  id: Scalars["String"]
}

export type QueryFormSubmissionsArgs = {
  filter?: InputMaybe<FormSubmissionsFilterInput>
  formId: Scalars["String"]
  orderBy?: InputMaybe<FormSubmissionsOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetMyParcelLabelUrlArgs = {
  myParcelId: Scalars["String"]
}

export type QueryInvoiceArgs = {
  id: Scalars["String"]
}

export type QueryInvoicesArgs = {
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryLeadArgs = {
  id: Scalars["String"]
}

export type QueryLeadsArgs = {
  filter?: InputMaybe<LeadsFilterInput>
  orderBy?: InputMaybe<QueryLeadsOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryOrderArgs = {
  id: Scalars["Int"]
}

export type QueryOrderByIdAndEmailArgs = {
  email: Scalars["String"]
  id: Scalars["Int"]
}

export type QueryOrderForReturnArgs = {
  email: Scalars["String"]
  id: Scalars["Int"]
}

export type QueryOrdersArgs = {
  filter?: InputMaybe<OrdersFilterInput>
  orderBy?: InputMaybe<QueryOrdersOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryOriginalValueGiftcardArgs = {
  input: OriginalValueGiftcardInput
}

export type QueryPaymentMethodsArgs = {
  amount: Scalars["Int"]
}

export type QueryPostalCodeLookupArgs = {
  houseNumber: Scalars["Int"]
  postalCode: Scalars["String"]
}

export type QueryPublicWishlistArgs = {
  id: Scalars["String"]
}

export type QueryTourByCodeAndEmailArgs = {
  code: Scalars["String"]
  email: Scalars["String"]
}

export type QueryUserFileArgs = {
  id: Scalars["String"]
}

export type QueryUserFilesArgs = {
  orderBy?: InputMaybe<QueryFilesOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryUserFormSubmissionArgs = {
  formId: Scalars["String"]
}

export type QueryValidateCurrentPasswordArgs = {
  password: Scalars["String"]
}

export type QueryVerifyAdyenPaymentArgs = {
  payload: Scalars["String"]
}

export type QueryVoucherArgs = {
  id: Scalars["String"]
}

export type QueryVouchersArgs = {
  filter?: InputMaybe<VouchersFilterInput>
  orderBy?: InputMaybe<QueryVouchersOrderByInput>
  skip?: InputMaybe<Scalars["Int"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryWishlistArgs = {
  id: Scalars["String"]
}

export type Address = {
  __typename?: "Address"
  city: Scalars["String"]
  companyName?: Maybe<Scalars["String"]>
  country: Scalars["String"]
  createdAt: Scalars["DateTime"]
  firstName: Scalars["String"]
  gender: Gender
  houseNumber: Scalars["String"]
  houseNumberSuffix?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  isBillingAddress: Scalars["Boolean"]
  isShippingAddress: Scalars["Boolean"]
  lastName: Scalars["String"]
  postalCode: Scalars["String"]
  state?: Maybe<Scalars["String"]>
  street: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  user: User
}

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export type User = {
  __typename?: "User"
  access: Array<UserAccess>
  cart?: Maybe<Cart>
  createdAt: Scalars["DateTime"]
  dafUser?: Maybe<DafUser>
  dafUserId?: Maybe<Scalars["String"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  fullName: Scalars["String"]
  id: Scalars["String"]
  lastLogin?: Maybe<Scalars["DateTime"]>
  lastName: Scalars["String"]
  role: Role
  status: UserStatus
  updatedAt: Scalars["DateTime"]
  wishlist: Array<Scalars["String"]>
}

export enum UserAccess {
  Admin = "ADMIN",
  Form = "FORM",
  Lead = "LEAD",
  Order = "ORDER",
  PreOwnedLead = "PRE_OWNED_LEAD",
  PreOwnedOrder = "PRE_OWNED_ORDER",
  Voucher = "VOUCHER",
}

export type Cart = {
  __typename?: "Cart"
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  items?: Maybe<Array<Maybe<CartItem>>>
  privilegeItems?: Maybe<Array<Maybe<PrivilegeCartItem>>>
  shippingCosts: Scalars["Int"]
  store: Store
  totalItems?: Maybe<Scalars["Int"]>
  totalPriceExTax?: Maybe<Scalars["Int"]>
  totalPriceIncTax?: Maybe<Scalars["Int"]>
  totalTax?: Maybe<Scalars["Int"]>
  updatedAt: Scalars["DateTime"]
  user?: Maybe<User>
  vouchers: Array<CartVoucher>
}

export type CartVouchersArgs = {
  after?: InputMaybe<CartVoucherWhereUniqueInput>
  before?: InputMaybe<CartVoucherWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type CartItem = {
  __typename?: "CartItem"
  article?: Maybe<Article>
  articleId: Scalars["String"]
  choicesArticle?: Maybe<Scalars["Json"]>
  choicesArticleCombination?: Maybe<Scalars["Json"]>
  description: Scalars["String"]
  id: Scalars["String"]
  message?: Maybe<Scalars["String"]>
  quantity: Scalars["Int"]
  size?: Maybe<Scalars["String"]>
  tax: Scalars["Float"]
  totalPrice?: Maybe<Scalars["Int"]>
  unitPrice: Scalars["Int"]
}

export type Article = {
  __typename?: "Article"
  articleAttributes?: Maybe<Array<Maybe<ArticleAttribute>>>
  articleType?: Maybe<Scalars["String"]>
  articleTypeID?: Maybe<Scalars["Int"]>
  brand?: Maybe<Scalars["String"]>
  collection?: Maybe<Scalars["String"]>
  collectionNumber?: Maybe<Scalars["Int"]>
  description?: Maybe<Scalars["String"]>
  eancode?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["String"]>
  ignoreStockCheck?: Maybe<Scalars["Boolean"]>
  images?: Maybe<Array<Maybe<Scalars["String"]>>>
  inStock?: Maybe<Scalars["Boolean"]>
  isOutOfCollection?: Maybe<Scalars["Boolean"]>
  isPriceVisible?: Maybe<Scalars["Boolean"]>
  isWebShopOnly?: Maybe<Scalars["Boolean"]>
  isWebshopSale?: Maybe<Scalars["Boolean"]>
  modelName?: Maybe<Scalars["String"]>
  price?: Maybe<Scalars["Int"]>
  salePrice?: Maybe<Scalars["Int"]>
  subCategory?: Maybe<Scalars["String"]>
  supplierNr?: Maybe<Scalars["String"]>
  urlSlug?: Maybe<Scalars["String"]>
  webID?: Maybe<Scalars["String"]>
  webShopStatus?: Maybe<Scalars["String"]>
}

export type ArticleAttribute = {
  __typename?: "ArticleAttribute"
  attributeID?: Maybe<Scalars["Int"]>
  attributeName?: Maybe<Scalars["String"]>
  attributeOriginalName?: Maybe<Scalars["String"]>
  attributeValue?: Maybe<Scalars["String"]>
  attributeValueID?: Maybe<Scalars["Int"]>
}

export type PrivilegeCartItem = {
  __typename?: "PrivilegeCartItem"
  articleId: Scalars["String"]
  description: Scalars["String"]
  id: Scalars["String"]
  imageSource: Scalars["String"]
  privilegeId: Scalars["String"]
  tax: Scalars["Float"]
  value: Scalars["Int"]
}

export enum Store {
  Choices = "CHOICES",
  Gassan = "GASSAN",
  PreOwned = "PRE_OWNED",
}

export type CartVoucherWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type CartVoucher = {
  __typename?: "CartVoucher"
  code: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  type: VoucherType
  value: Scalars["Int"]
}

export enum VoucherType {
  Absolute = "ABSOLUTE",
  Percentage = "PERCENTAGE",
}

export type DafUser = {
  __typename?: "DAFUser"
  geboortedatum?: Maybe<Scalars["String"]>
  /** m | v */
  geslacht?: Maybe<Scalars["String"]>
  huisnummer?: Maybe<Scalars["Int"]>
  huisnummertoevoeging?: Maybe<Scalars["String"]>
  isokode?: Maybe<Scalars["String"]>
  klant_id?: Maybe<Scalars["String"]>
  klant_nr?: Maybe<Scalars["Int"]>
  Klantnaam?: Maybe<Scalars["String"]>
  loyalty_progress?: Maybe<Scalars["Float"]>
  mobiel?: Maybe<Scalars["String"]>
  my_loyalty?: Maybe<Scalars["String"]>
  optins?: Maybe<Array<Maybe<DafUserOptin>>>
  pgeboortedatum?: Maybe<Scalars["String"]>
  pgeslacht?: Maybe<Scalars["String"]>
  plaatsnaam?: Maybe<Scalars["String"]>
  pnaam?: Maybe<Scalars["String"]>
  polsmaat?: Maybe<Scalars["Int"]>
  postkode?: Maybe<Scalars["String"]>
  pvoorletters?: Maybe<Scalars["String"]>
  pvoornaam?: Maybe<Scalars["String"]>
  ringmaat_links?: Maybe<Scalars["Int"]>
  ringmaat_rechts?: Maybe<Scalars["Int"]>
  straatnaam?: Maybe<Scalars["String"]>
  telefoon?: Maybe<Scalars["String"]>
  trouwdatum?: Maybe<Scalars["String"]>
  voorletters?: Maybe<Scalars["String"]>
  voornaam?: Maybe<Scalars["String"]>
}

export type DafUserOptin = {
  __typename?: "DAFUserOptin"
  description?: Maybe<Scalars["String"]>
  optin?: Maybe<Scalars["String"]>
  value?: Maybe<Scalars["Boolean"]>
}

export enum Role {
  Admin = "ADMIN",
  User = "USER",
}

export enum UserStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  Deleted = "DELETED",
  Pending = "PENDING",
}

export type QueryUsersWhereInput = {
  id?: InputMaybe<StringFilter>
  role?: InputMaybe<EnumRoleFilter>
  status?: InputMaybe<EnumUserStatusFilter>
}

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]>
  endsWith?: InputMaybe<Scalars["String"]>
  equals?: InputMaybe<Scalars["String"]>
  gt?: InputMaybe<Scalars["String"]>
  gte?: InputMaybe<Scalars["String"]>
  in?: InputMaybe<Array<Scalars["String"]>>
  lt?: InputMaybe<Scalars["String"]>
  lte?: InputMaybe<Scalars["String"]>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars["String"]>>
  startsWith?: InputMaybe<Scalars["String"]>
}

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]>
  endsWith?: InputMaybe<Scalars["String"]>
  equals?: InputMaybe<Scalars["String"]>
  gt?: InputMaybe<Scalars["String"]>
  gte?: InputMaybe<Scalars["String"]>
  in?: InputMaybe<Array<Scalars["String"]>>
  lt?: InputMaybe<Scalars["String"]>
  lte?: InputMaybe<Scalars["String"]>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars["String"]>>
  startsWith?: InputMaybe<Scalars["String"]>
}

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>
  in?: InputMaybe<Array<Role>>
  not?: InputMaybe<NestedEnumRoleFilter>
  notIn?: InputMaybe<Array<Role>>
}

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>
  in?: InputMaybe<Array<Role>>
  not?: InputMaybe<NestedEnumRoleFilter>
  notIn?: InputMaybe<Array<Role>>
}

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>
  in?: InputMaybe<Array<UserStatus>>
  not?: InputMaybe<NestedEnumUserStatusFilter>
  notIn?: InputMaybe<Array<UserStatus>>
}

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>
  in?: InputMaybe<Array<UserStatus>>
  not?: InputMaybe<NestedEnumUserStatusFilter>
  notIn?: InputMaybe<Array<UserStatus>>
}

export type QueryUsersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
  email?: InputMaybe<SortOrder>
  fullName?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastLogin?: InputMaybe<SortOrder>
  role?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type AdminsPayload = {
  __typename?: "AdminsPayload"
  items?: Maybe<Array<Maybe<User>>>
  total?: Maybe<Scalars["Int"]>
}

export type ArticlesFilterInput = {
  articleTypeID?: InputMaybe<Scalars["Int"]>
  attributeFilters?: InputMaybe<Array<InputMaybe<AttributeFilterFiltersInput>>>
  brands?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>
  collections?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>
  onlyMemberDiscountProducts?: InputMaybe<Scalars["Boolean"]>
  theme?: InputMaybe<Scalars["String"]>
  webID?: InputMaybe<Scalars["String"]>
}

export type AttributeFilterFiltersInput = {
  attributeID: Scalars["Int"]
  attributeValueID: Array<Scalars["Int"]>
}

export type ArticlesPaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>
  skip?: InputMaybe<Scalars["Int"]>
  sort?: InputMaybe<ArticleSortEnum>
}

export enum ArticleSortEnum {
  New = "new",
  Populair = "populair",
  PriceAsc = "priceASC",
  PriceDesc = "priceDESC",
}

export type ArticlesPayload = {
  __typename?: "ArticlesPayload"
  items?: Maybe<Array<Maybe<Article>>>
  total?: Maybe<Scalars["Int"]>
}

export type ChoicesArticle = {
  __typename?: "ChoicesArticle"
  component?: Maybe<Scalars["String"]>
  cut?: Maybe<Scalars["String"]>
  eancode?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  material?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  price: Scalars["Int"]
  properties?: Maybe<Scalars["JSON"]>
  size?: Maybe<Scalars["String"]>
  slug: Scalars["String"]
  style?: Maybe<Scalars["String"]>
  substyle?: Maybe<Scalars["String"]>
}

export type ChoicesArticleCombination = {
  __typename?: "ChoicesArticleCombination"
  articles: Array<ChoicesArticleCombinationArticle>
  baseMaterial?: Maybe<Scalars["String"]>
  collection: Scalars["String"]
  combi_no: Scalars["Int"]
  eancode?: Maybe<Scalars["String"]>
  gemstone: Scalars["String"]
  hasFixedComponent: Scalars["Boolean"]
  id: Scalars["String"]
  images: Array<ChoicesArticleCombinationImage>
  material?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  price: Scalars["Int"]
  product_group: Scalars["String"]
  seq_nr?: Maybe<Scalars["String"]>
  size?: Maybe<Scalars["String"]>
  slug: Scalars["String"]
  style: Scalars["String"]
  substyle?: Maybe<Scalars["String"]>
}

export type ChoicesArticleCombinationArticle = {
  __typename?: "ChoicesArticleCombinationArticle"
  component: Scalars["String"]
  id: Scalars["String"]
  price: Scalars["Int"]
  quantity: Scalars["Int"]
}

export type ChoicesArticleCombinationImage = {
  __typename?: "ChoicesArticleCombinationImage"
  path: Scalars["String"]
  position: Scalars["Int"]
}

export type ChoicesArticleCombinationsFilter = {
  chainCollection?: InputMaybe<Scalars["Boolean"]>
  material?: InputMaybe<ChoicesMaterialFilter>
  productGroup?: InputMaybe<ChoicesProductGroupFilter>
  size?: InputMaybe<ChoicesSizeFilter>
  style?: InputMaybe<ChoicesStyleFilter>
  themeProfile?: InputMaybe<ChoicesThemeProfileCodeFilter>
}

export enum ChoicesMaterialFilter {
  Ceramic = "ceramic",
  Diamonds = "diamonds",
  Rosegold = "rosegold",
  Whitegold = "whitegold",
  Yellowgold = "yellowgold",
}

export enum ChoicesProductGroupFilter {
  Bracelet = "bracelet",
  Earring = "earring",
  Necklace = "necklace",
  Ring = "ring",
}

export enum ChoicesSizeFilter {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export enum ChoicesStyleFilter {
  Classic = "classic",
  Hippiechic = "hippiechic",
  Onepiece = "onepiece",
  Oval = "oval",
  Twin = "twin",
}

export enum ChoicesThemeProfileCodeFilter {
  Chaincollection = "CHAINCOLLECTION",
  Newcollection = "NEWCOLLECTION",
}

export type ChoicesArticleCombinationsPagination = {
  limit?: InputMaybe<Scalars["Int"]>
  skip?: InputMaybe<Scalars["String"]>
}

export type ChoicesArticleCombinationsPayload = {
  __typename?: "ChoicesArticleCombinationsPayload"
  dynamicFilters: Scalars["JSON"]
  fixedFilters: Scalars["JSON"]
  items: Array<ChoicesArticleCombination>
  total: Scalars["Int"]
}

export type ChoicesArticlesFilter = {
  component?: InputMaybe<ChoicesComponentFilter>
  cut?: InputMaybe<ChoicesCutFilter>
  material?: InputMaybe<ChoicesMaterialFilter>
  productGroup?: InputMaybe<ChoicesProductGroupFilter>
  size?: InputMaybe<ChoicesSizeFilter>
  style: ChoicesStyleFilter
  substyle?: InputMaybe<ChoicesSubStyleFilter>
}

export enum ChoicesComponentFilter {
  Base = "base",
  Fixed = "fixed",
  Frame = "frame",
  Stone = "stone",
}

export enum ChoicesCutFilter {
  Cabochon = "cabochon",
  Faceted = "faceted",
}

export enum ChoicesSubStyleFilter {
  EarringBase_1212 = "earring_base_1212",
  EarringBaseAjour = "earring_base_ajour",
  EarringBaseAnimal = "earring_base_animal",
  EarringBaseBohemian = "earring_base_bohemian",
  EarringBaseCapsule = "earring_base_capsule",
  EarringBaseDiamonds_0709 = "earring_base_diamonds_0709",
  EarringBaseEveningPlainDiamonds = "earring_base_evening_plain_diamonds",
  EarringBaseGalaxy = "earring_base_galaxy",
  EarringBaseGeo = "earring_base_geo",
  EarringBaseHippieFlower = "earring_base_hippie_flower",
  EarringBaseHippieLeaf = "earring_base_hippie_leaf",
  EarringBaseHippiePearsh = "earring_base_hippie_pearsh",
  EarringBaseHippiePearshape = "earring_base_hippie_pearshape",
  EarringBaseHippiePython = "earring_base_hippie_python",
  EarringBaseHippieStar = "earring_base_hippie_star",
  EarringBaseLink = "earring_base_link",
  EarringBaseMini_0808 = "earring_base_mini_0808",
  EarringBasePlainDiamonds = "earring_base_plain_diamonds",
  EarringBaseRaindrop = "earring_base_raindrop",
  EarringBaseSimplicity = "earring_base_simplicity",
  EarringBaseSimplicityDiamonds = "earring_base_simplicity_diamonds",
  RingBase_0808 = "ring_base_0808",
  RingBase_1212 = "ring_base_1212",
  RingBase_1318 = "ring_base_1318",
  RingBase_1622 = "ring_base_1622",
  RingBase_1818 = "ring_base_1818",
  RingBaseAjour_1212 = "ring_base_ajour_1212",
  RingBaseAjour_1318 = "ring_base_ajour_1318",
  RingBaseAjour_1622 = "ring_base_ajour_1622",
  RingBaseAjour_1818 = "ring_base_ajour_1818",
  RingBaseCeramic_1818 = "ring_base_ceramic_1818",
  RingBaseCore_1212 = "ring_base_core_1212",
  RingBaseCore_1622 = "ring_base_core_1622",
  RingBaseCurlLong_0709 = "ring_base_curl_long_0709",
  RingBaseDiamonds_0709 = "ring_base_diamonds_0709",
  RingBaseDiamonds_1818 = "ring_base_diamonds_1818",
  RingBaseStiletto_0709 = "ring_base_stiletto_0709",
  RingBaseStiletto_0808 = "ring_base_stiletto_0808",
  RingBaseTwist_0808 = "ring_base_twist_0808",
}

export type ChoicesArticlesPagination = {
  limit?: InputMaybe<Scalars["Int"]>
  skip?: InputMaybe<Scalars["String"]>
}

export type ChoicesArticlesPayload = {
  __typename?: "ChoicesArticlesPayload"
  dynamicFilters: Scalars["JSON"]
  fixedFilters: Scalars["JSON"]
  items: Array<ChoicesArticle>
  total: Scalars["Int"]
}

export type ChoicesRelatedCombination = {
  __typename?: "ChoicesRelatedCombination"
  combinations: Array<ChoicesArticleCombination>
  id: Scalars["String"]
  productGroup: Scalars["String"]
  size: Scalars["String"]
  style: Scalars["String"]
}

export type ChoicesRelatedCombinationsFilter = {
  productGroup?: InputMaybe<ChoicesProductGroupFilter>
  size?: InputMaybe<ChoicesSizeFilter>
  style?: InputMaybe<ChoicesStyleFilter>
}

export type ChoicesRelatedCombinationsPagination = {
  cursor?: InputMaybe<Scalars["Int"]>
  limit?: InputMaybe<Scalars["Int"]>
}

export type ChoicesRelatedCombinationsPayload = {
  __typename?: "ChoicesRelatedCombinationsPayload"
  filters: ChoicesRelatedCombinationFilters
  items: Array<ChoicesRelatedCombination>
  total: Scalars["Int"]
}

export type ChoicesRelatedCombinationFilters = {
  __typename?: "ChoicesRelatedCombinationFilters"
  productGroup: Array<ChoicesRelatedCombinationFiltersItem>
  size: Array<ChoicesRelatedCombinationFiltersItem>
  style: Array<ChoicesRelatedCombinationFiltersItem>
}

export type ChoicesRelatedCombinationFiltersItem = {
  __typename?: "ChoicesRelatedCombinationFiltersItem"
  name: Scalars["String"]
  total: Scalars["Int"]
}

export type FileWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type QueryFilesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
}

export type File = {
  __typename?: "File"
  createdAt: Scalars["DateTime"]
  filename: Scalars["String"]
  id: Scalars["String"]
  mimetype: Scalars["String"]
  name: Scalars["String"]
  originalFilename: Scalars["String"]
  type: FileType
  updatedAt: Scalars["DateTime"]
  uploadedBy?: Maybe<User>
  userId?: Maybe<Scalars["String"]>
}

export enum FileType {
  Invoice = "INVOICE",
  Other = "OTHER",
  Receipt = "RECEIPT",
  WarrantyCard = "WARRANTY_CARD",
}

export type FilterPayload = {
  __typename?: "FilterPayload"
  articleAttributeFilters?: Maybe<Array<Maybe<ArticleAttributeFilter>>>
  articleTypeFilters?: Maybe<Array<Maybe<ArticleTypeFilter>>>
  brandCollectionFilters?: Maybe<Array<Maybe<BrandCollectionFilter>>>
  brandFilters?: Maybe<Array<Maybe<BrandTypeFilter>>>
}

export type ArticleAttributeFilter = {
  __typename?: "ArticleAttributeFilter"
  articleFilterAttributeValues?: Maybe<
    Array<Maybe<ArticleFilterAttributeValue>>
  >
  attributeID?: Maybe<Scalars["Int"]>
  attributeName?: Maybe<Scalars["String"]>
}

export type ArticleFilterAttributeValue = {
  __typename?: "ArticleFilterAttributeValue"
  attributeValue?: Maybe<Scalars["String"]>
  attributeValueID?: Maybe<Scalars["Int"]>
}

export type ArticleTypeFilter = {
  __typename?: "ArticleTypeFilter"
  articleType?: Maybe<Scalars["String"]>
  articleTypeID?: Maybe<Scalars["Int"]>
}

export type BrandCollectionFilter = {
  __typename?: "BrandCollectionFilter"
  collection?: Maybe<Scalars["String"]>
  collectionNumber?: Maybe<Scalars["Int"]>
}

export type BrandTypeFilter = {
  __typename?: "BrandTypeFilter"
  brand?: Maybe<Scalars["String"]>
  merk_nr?: Maybe<Scalars["Int"]>
}

export type Form = {
  __typename?: "Form"
  adminEmailRecipient?: Maybe<Scalars["String"]>
  adminEmailSubject?: Maybe<Scalars["String"]>
  autoReplyTemplates?: Maybe<Scalars["JSON"]>
  createdAt: Scalars["DateTime"]
  customNewsletterText?: Maybe<Scalars["JSON"]>
  customNumberOfPersons?: Maybe<Scalars["Int"]>
  fields: Array<FormField>
  id: Scalars["ID"]
  lastSubmissionDate?: Maybe<Scalars["DateTime"]>
  limitToOneSubmission: Scalars["Boolean"]
  name: Scalars["String"]
  note?: Maybe<Scalars["String"]>
  selectableDates: Array<Scalars["String"]>
  selectableTimes: Array<Scalars["String"]>
  store: FormStore
  submissionsCount: Scalars["Int"]
  updatedAt: Scalars["DateTime"]
}

export enum FormField {
  CreateAccount = "CREATE_ACCOUNT",
  Date = "DATE",
  DietaryRequirements = "DIETARY_REQUIREMENTS",
  GassanPlazaNews = "GASSAN_PLAZA_NEWS",
  HeardOfGassanVia = "HEARD_OF_GASSAN_VIA",
  HomeAddress = "HOME_ADDRESS",
  Message = "MESSAGE",
  NewsletterMandatory = "NEWSLETTER_MANDATORY",
  NumberOfPersons = "NUMBER_OF_PERSONS",
  PhoneNumber = "PHONE_NUMBER",
  Time = "TIME",
}

export enum FormStore {
  Gassan = "GASSAN",
  Trophy = "TROPHY",
}

export type FormsFilterInput = {
  query?: InputMaybe<Scalars["String"]>
}

export type FormsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
}

export type FormsPayload = {
  __typename?: "FormsPayload"
  items: Array<Form>
  total: Scalars["Int"]
}

export type FormSubmission = {
  __typename?: "FormSubmission"
  createdAt: Scalars["DateTime"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  id: Scalars["ID"]
  lastName: Scalars["String"]
  preferredLanguage: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  values: Scalars["JSON"]
}

export type FormSubmissionsFilterInput = {
  query?: InputMaybe<Scalars["String"]>
}

export type FormSubmissionsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
}

export type FormSubmissionsPayload = {
  __typename?: "FormSubmissionsPayload"
  items: Array<FormSubmission>
  total: Scalars["Int"]
}

export type Giftcard = {
  __typename?: "Giftcard"
  code: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  originalValue?: Maybe<Scalars["Int"]>
  updatedAt: Scalars["DateTime"]
  user: User
}

export type Invoice = {
  __typename?: "Invoice"
  adres?: Maybe<Scalars["String"]>
  bonnr?: Maybe<Scalars["Int"]>
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  date?: Maybe<Scalars["DateTime"]>
  datum?: Maybe<Scalars["String"]>
  discount?: Maybe<Scalars["Int"]>
  email?: Maybe<Scalars["String"]>
  first_name?: Maybe<Scalars["String"]>
  /** The id is build up like: {administratienummer}{locatie_nr}{bonnr} */
  id?: Maybe<Scalars["String"]>
  /** The idRef is build up like: {administratienummer}-{locatie_nr}-{bonnr} */
  idRef?: Maybe<Scalars["String"]>
  klant_naam?: Maybe<Scalars["String"]>
  location?: Maybe<InvoiceLocation>
  order?: Maybe<Order>
  phone?: Maybe<Scalars["String"]>
  postalcode?: Maybe<Scalars["String"]>
  sales_lines?: Maybe<Array<Maybe<InvoiceSalesLine>>>
  sales_payments?: Maybe<Array<Maybe<InvoiceSalesPayment>>>
  shipping_cost?: Maybe<Scalars["Int"]>
  tijd?: Maybe<Scalars["Int"]>
  webshop_order_id?: Maybe<Scalars["Int"]>
}

export type InvoiceLocation = {
  __typename?: "InvoiceLocation"
  locatie_nr?: Maybe<Scalars["Int"]>
  location?: Maybe<Scalars["String"]>
}

export type Order = {
  __typename?: "Order"
  activityLog: Array<ActivityLogEntry>
  billingAddress: OrderAddress
  contactEmail: Scalars["String"]
  contactFullName: Scalars["String"]
  contactPhone: Scalars["String"]
  createdAt: Scalars["DateTime"]
  deliveryType: OrderDeliveryType
  giftWrap: Scalars["Boolean"]
  giftWrapText?: Maybe<Scalars["String"]>
  id: Scalars["Int"]
  items?: Maybe<Array<Maybe<OrderItem>>>
  key: Scalars["String"]
  message?: Maybe<Scalars["String"]>
  privilegeItems?: Maybe<Array<Maybe<PrivilegeOrderItem>>>
  shippingAddress: OrderAddress
  shippingCosts: Scalars["Int"]
  status: OrderStatus
  store: Store
  totalDiscount: Scalars["Int"]
  totalPriceExTax: Scalars["Int"]
  totalPriceIncTax: Scalars["Int"]
  totalTax: Scalars["Int"]
  transactions: Array<Transaction>
  type: OrderType
  updatedAt: Scalars["DateTime"]
  user?: Maybe<User>
  vouchers: Array<OrderVoucher>
}

export type OrderActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<Array<OrderActivityLogOrderByInput>>
}

export type OrderTransactionsArgs = {
  after?: InputMaybe<TransactionWhereUniqueInput>
  before?: InputMaybe<TransactionWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type OrderVouchersArgs = {
  after?: InputMaybe<OrderVoucherWhereUniqueInput>
  before?: InputMaybe<OrderVoucherWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type ActivityLogEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type OrderActivityLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
}

export type ActivityLogEntry = {
  __typename?: "ActivityLogEntry"
  change?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  memo?: Maybe<Scalars["String"]>
  subject?: Maybe<Scalars["String"]>
  type: ActivityLogEntryType
  updatedAt: Scalars["DateTime"]
  user?: Maybe<User>
}

export enum ActivityLogEntryType {
  Created = "CREATED",
  Memo = "MEMO",
  StatusChange = "STATUS_CHANGE",
  Sync = "SYNC",
  Update = "UPDATE",
}

export type OrderAddress = {
  __typename?: "OrderAddress"
  city: Scalars["String"]
  companyName?: Maybe<Scalars["String"]>
  country: Scalars["String"]
  firstName: Scalars["String"]
  gender: Gender
  houseNumber: Scalars["String"]
  houseNumberSuffix?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  lastName: Scalars["String"]
  postalCode: Scalars["String"]
  state?: Maybe<Scalars["String"]>
  street: Scalars["String"]
}

export enum OrderDeliveryType {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export type OrderItem = {
  __typename?: "OrderItem"
  article?: Maybe<Article>
  articleId: Scalars["String"]
  choicesArticle?: Maybe<Scalars["Json"]>
  choicesArticleCombination?: Maybe<Scalars["Json"]>
  createdAt: Scalars["DateTime"]
  description: Scalars["String"]
  id: Scalars["String"]
  message?: Maybe<Scalars["String"]>
  quantity: Scalars["Int"]
  size?: Maybe<Scalars["String"]>
  tax: Scalars["Float"]
  totalPrice?: Maybe<Scalars["Int"]>
  unitPrice: Scalars["Int"]
  updatedAt: Scalars["DateTime"]
}

export type PrivilegeOrderItem = {
  __typename?: "PrivilegeOrderItem"
  article?: Maybe<Article>
  articleId: Scalars["String"]
  createdAt: Scalars["DateTime"]
  description: Scalars["String"]
  id: Scalars["String"]
  imageSource: Scalars["String"]
  privilegeId: Scalars["String"]
  tax: Scalars["Float"]
  updatedAt: Scalars["DateTime"]
  value: Scalars["Int"]
}

export enum OrderStatus {
  Fullfilled = "FULLFILLED",
  New = "NEW",
  NoStock = "NO_STOCK",
  Payed = "PAYED",
  ReturnProcessed = "RETURN_PROCESSED",
  ReturnRegistered = "RETURN_REGISTERED",
}

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type Transaction = {
  __typename?: "Transaction"
  amount: Scalars["Int"]
  id: Scalars["String"]
  paymentMethod?: Maybe<Scalars["String"]>
  reference?: Maybe<Scalars["String"]>
  type: TransactionType
}

export enum TransactionType {
  Adyen = "ADYEN",
  Giftcard = "GIFTCARD",
  Paypal = "PAYPAL",
}

export enum OrderType {
  ProductOrder = "PRODUCT_ORDER",
  TourOrder = "TOUR_ORDER",
}

export type OrderVoucherWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type OrderVoucher = {
  __typename?: "OrderVoucher"
  code: Scalars["String"]
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  type: VoucherType
  value: Scalars["Int"]
}

export type InvoiceSalesLine = {
  __typename?: "InvoiceSalesLine"
  article?: Maybe<Article>
  artikel_bedrag_excl?: Maybe<Scalars["Float"]>
  artikel_bedrag_incl?: Maybe<Scalars["Float"]>
  artikel_karaat?: Maybe<Scalars["Float"]>
  artikel_nr?: Maybe<Scalars["String"]>
  artikel_prijs_excl?: Maybe<Scalars["Float"]>
  artikel_prijs_incl?: Maybe<Scalars["Float"]>
  artikel_stuks?: Maybe<Scalars["Int"]>
  description?: Maybe<Scalars["String"]>
  taxfree?: Maybe<Scalars["Boolean"]>
}

export type InvoiceSalesPayment = {
  __typename?: "InvoiceSalesPayment"
  betaalwijze?: Maybe<Scalars["String"]>
  betalingsbedrag?: Maybe<Scalars["Float"]>
  betalingsbedrag_val?: Maybe<Scalars["Float"]>
  valutakode?: Maybe<Scalars["String"]>
}

export type InvoicesPayload = {
  __typename?: "InvoicesPayload"
  items?: Maybe<Array<Maybe<Invoice>>>
  total?: Maybe<Scalars["Int"]>
}

export type Lead = {
  __typename?: "Lead"
  activityLog: Array<ActivityLogEntry>
  articleId?: Maybe<Scalars["String"]>
  brand?: Maybe<Scalars["String"]>
  contactPreference?: Maybe<LeadContactPreference>
  createdAt: Scalars["DateTime"]
  email: Scalars["String"]
  fileIds: Array<File>
  fileUrls?: Maybe<Array<Maybe<Scalars["String"]>>>
  firstName?: Maybe<Scalars["String"]>
  gender: Gender
  id: Scalars["String"]
  language: LeadLanguage
  lastName?: Maybe<Scalars["String"]>
  leadStore: LeadStore
  location?: Maybe<Scalars["String"]>
  message?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
  preferredDate?: Maybe<Scalars["String"]>
  preferredStore?: Maybe<Scalars["String"]>
  preOwnedAdditionalItems?: Maybe<LeadAdditionalItems>
  preOwnedBrand?: Maybe<Scalars["String"]>
  preOwnedExpectedPrice?: Maybe<Scalars["String"]>
  preOwnedModel?: Maybe<Scalars["String"]>
  preOwnedReference?: Maybe<Scalars["String"]>
  preOwnedTradeType?: Maybe<LeadTradeType>
  preOwnedYear?: Maybe<Scalars["String"]>
  productName?: Maybe<Scalars["String"]>
  referrer: Scalars["String"]
  referrerTitle: Scalars["String"]
  responses: Array<LeadResponse>
  status: LeadStatus
  store?: Maybe<Scalars["String"]>
  subject?: Maybe<Scalars["String"]>
  type: LeadType
  updatedAt: Scalars["DateTime"]
}

export type LeadActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<Array<LeadActivityLogOrderByInput>>
}

export type LeadFileIdsArgs = {
  after?: InputMaybe<FileWhereUniqueInput>
  before?: InputMaybe<FileWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
}

export type LeadResponsesArgs = {
  after?: InputMaybe<LeadResponseWhereUniqueInput>
  before?: InputMaybe<LeadResponseWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<Array<LeadResponsesOrderByInput>>
}

export type LeadActivityLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
}

export enum LeadContactPreference {
  Email = "EMAIL",
  Phone = "PHONE",
}

export enum LeadLanguage {
  De = "DE",
  En = "EN",
  Nl = "NL",
}

export enum LeadStore {
  Gassan = "GASSAN",
  PreOwned = "PRE_OWNED",
}

export enum LeadAdditionalItems {
  JustWatch = "JUST_WATCH",
  OriginalBox = "ORIGINAL_BOX",
  OriginalBoxPapers = "ORIGINAL_BOX_PAPERS",
  OriginalPapers = "ORIGINAL_PAPERS",
}

export enum LeadTradeType {
  Sell = "SELL",
  Trade = "TRADE",
}

export type LeadResponseWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type LeadResponsesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
}

export type LeadResponse = {
  __typename?: "LeadResponse"
  createdAt: Scalars["DateTime"]
  id: Scalars["String"]
  message: Scalars["String"]
  user: User
}

export enum LeadStatus {
  Answered = "ANSWERED",
  Forwarded = "FORWARDED",
  InProgress = "IN_PROGRESS",
  New = "NEW",
}

export enum LeadType {
  Appointment = "APPOINTMENT",
  Contact = "CONTACT",
  Question = "QUESTION",
  SubmitPreOwnedWatch = "SUBMIT_PRE_OWNED_WATCH",
}

export type LeadsFilterInput = {
  from?: InputMaybe<Scalars["DateTime"]>
  query?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Array<InputMaybe<LeadStatus>>>
  store?: InputMaybe<Array<InputMaybe<LeadStore>>>
  till?: InputMaybe<Scalars["DateTime"]>
  type?: InputMaybe<Array<InputMaybe<LeadType>>>
}

export type QueryLeadsOrderByInput = {
  id?: InputMaybe<SortOrder>
  leadStore?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  referrerTitle?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
}

export type LeadsPayload = {
  __typename?: "LeadsPayload"
  items?: Maybe<Array<Maybe<Lead>>>
  total?: Maybe<Scalars["Int"]>
}

export type DafLoyaltyStatus = {
  __typename?: "DAFLoyaltyStatus"
  loyalty_description?: Maybe<Scalars["String"]>
  loyalty_nr?: Maybe<Scalars["Int"]>
  loyalty_progress?: Maybe<Scalars["Float"]>
}

export type OrdersFilterInput = {
  from?: InputMaybe<Scalars["String"]>
  query?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Array<InputMaybe<OrderStatus>>>
  store?: InputMaybe<Array<InputMaybe<Store>>>
  till?: InputMaybe<Scalars["String"]>
}

export type QueryOrdersOrderByInput = {
  contactFullName?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  totalPriceIncTax?: InputMaybe<SortOrder>
}

export type OrdersPayload = {
  __typename?: "OrdersPayload"
  items?: Maybe<Array<Maybe<Order>>>
  total?: Maybe<Scalars["Int"]>
}

export type OriginalValueGiftcardInput = {
  id: Scalars["String"]
  securityCode: Scalars["String"]
}

export type PostalCodeLookupPayload = {
  __typename?: "PostalCodeLookupPayload"
  city?: Maybe<Scalars["String"]>
  id?: Maybe<Scalars["String"]>
  municipality?: Maybe<Scalars["String"]>
  number?: Maybe<Scalars["Int"]>
  postcode?: Maybe<Scalars["String"]>
  province?: Maybe<Scalars["String"]>
  street?: Maybe<Scalars["String"]>
}

export type DafPreferenceTypeItem = {
  __typename?: "DAFPreferenceTypeItem"
  preference_type_description?: Maybe<Scalars["String"]>
  preference_type_id?: Maybe<Scalars["Int"]>
  preferences?: Maybe<Array<Maybe<DafPreferenceItem>>>
}

export type DafPreferenceItem = {
  __typename?: "DAFPreferenceItem"
  description?: Maybe<Scalars["String"]>
  isSelected?: Maybe<Scalars["Boolean"]>
  preference_no?: Maybe<Scalars["Int"]>
  profile?: Maybe<Scalars["String"]>
}

export type DafPrivilegeTypeItem = {
  __typename?: "DAFPrivilegeTypeItem"
  artikel_nr?: Maybe<Scalars["Int"]>
  beschikbaar?: Maybe<Scalars["Int"]>
  description?: Maybe<Scalars["String"]>
  max_aantal?: Maybe<Scalars["Int"]>
  preference_type_description?: Maybe<DafPrivilegeType>
  privilege_action?: Maybe<Scalars["String"]>
  privilege_nr?: Maybe<Scalars["Int"]>
}

export enum DafPrivilegeType {
  Events = "EVENTS",
  Message = "MESSAGE",
  PartnerUrl = "PartnerURL",
  Reservation = "RESERVATION",
  Sales = "SALES",
  Salesorder = "SALESORDER",
}

export type UserWishlist = {
  __typename?: "UserWishlist"
  articleIds: Array<Scalars["String"]>
  id: Scalars["ID"]
  status: UserWishlistStatus
}

export enum UserWishlistStatus {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type TourBooking = {
  __typename?: "TourBooking"
  client: TourBookingClient
  clientName?: Maybe<Scalars["String"]>
  code: Scalars["String"]
  countryOfOrigin?: Maybe<Scalars["String"]>
  createdAt: Scalars["DateTime"]
  date: Scalars["String"]
  email: Scalars["String"]
  firstName?: Maybe<Scalars["String"]>
  gender?: Maybe<Gender>
  groupType?: Maybe<Scalars["String"]>
  hotel?: Maybe<Scalars["String"]>
  id: Scalars["String"]
  lastName?: Maybe<Scalars["String"]>
  localGuide?: Maybe<Scalars["String"]>
  message?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  persons: Scalars["Int"]
  phone: Scalars["String"]
  prefferedLanguage: Scalars["String"]
  status: TourBookingStatus
  time: Scalars["String"]
  totalPrice: Scalars["Int"]
  tourLeader?: Maybe<Scalars["String"]>
  travelAgency?: Maybe<Scalars["String"]>
  type: TourBookingType
}

export enum TourBookingClient {
  Client = "CLIENT",
  TourOperator = "TOUR_OPERATOR",
}

export enum TourBookingStatus {
  Complete = "COMPLETE",
  WaitingForPayment = "WAITING_FOR_PAYMENT",
}

export enum TourBookingType {
  Free = "FREE",
  Payed = "PAYED",
}

export type FilesPayload = {
  __typename?: "FilesPayload"
  items?: Maybe<Array<Maybe<File>>>
  total?: Maybe<Scalars["Int"]>
}

export type Voucher = {
  __typename?: "Voucher"
  activityLog: Array<ActivityLogEntry>
  code: Scalars["String"]
  createdAt: Scalars["DateTime"]
  createdBy: User
  description: Scalars["String"]
  id: Scalars["String"]
  limit: Scalars["Int"]
  limitToBrands: Array<Scalars["String"]>
  minimalOrderValue: Scalars["Int"]
  status: VoucherStatus
  store: Store
  type: VoucherType
  updatedAt: Scalars["DateTime"]
  used: Scalars["Int"]
  validTill: Scalars["DateTime"]
  value: Scalars["Int"]
}

export type VoucherActivityLogArgs = {
  after?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  before?: InputMaybe<ActivityLogEntryWhereUniqueInput>
  first?: InputMaybe<Scalars["Int"]>
  last?: InputMaybe<Scalars["Int"]>
  orderBy?: InputMaybe<Array<ActivityLogEntryOrderByWithRelationInput>>
}

export type ActivityLogEntryOrderByWithRelationInput = {
  change?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lead?: InputMaybe<LeadOrderByWithRelationInput>
  leadId?: InputMaybe<SortOrder>
  memo?: InputMaybe<SortOrder>
  order?: InputMaybe<OrderOrderByWithRelationInput>
  orderId?: InputMaybe<SortOrder>
  subject?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
  Voucher?: InputMaybe<VoucherOrderByWithRelationInput>
  voucherId?: InputMaybe<SortOrder>
}

export type LeadOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>
  articleId?: InputMaybe<SortOrder>
  brand?: InputMaybe<SortOrder>
  contactPreference?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  email?: InputMaybe<SortOrder>
  fileIds?: InputMaybe<FileOrderByRelationAggregateInput>
  firstName?: InputMaybe<SortOrder>
  gender?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  language?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  leadStore?: InputMaybe<SortOrder>
  location?: InputMaybe<SortOrder>
  message?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  phone?: InputMaybe<SortOrder>
  preferredDate?: InputMaybe<SortOrder>
  preferredStore?: InputMaybe<SortOrder>
  preOwnedAdditionalItems?: InputMaybe<SortOrder>
  preOwnedBrand?: InputMaybe<SortOrder>
  preOwnedExpectedPrice?: InputMaybe<SortOrder>
  preOwnedModel?: InputMaybe<SortOrder>
  preOwnedReference?: InputMaybe<SortOrder>
  preOwnedTradeType?: InputMaybe<SortOrder>
  preOwnedYear?: InputMaybe<SortOrder>
  productName?: InputMaybe<SortOrder>
  referrer?: InputMaybe<SortOrder>
  referrerTitle?: InputMaybe<SortOrder>
  responses?: InputMaybe<LeadResponseOrderByRelationAggregateInput>
  status?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  subject?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type ActivityLogEntryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type FileOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type LeadResponseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type OrderOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>
  billingAddress?: InputMaybe<OrderAddressOrderByWithRelationInput>
  billingAddressId?: InputMaybe<SortOrder>
  contactEmail?: InputMaybe<SortOrder>
  contactFullName?: InputMaybe<SortOrder>
  contactPhone?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  deliveryType?: InputMaybe<SortOrder>
  giftWrap?: InputMaybe<SortOrder>
  giftWrapText?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  items?: InputMaybe<OrderItemOrderByRelationAggregateInput>
  key?: InputMaybe<SortOrder>
  message?: InputMaybe<SortOrder>
  preferredLanguage?: InputMaybe<SortOrder>
  privilegeItems?: InputMaybe<PrivilegeOrderItemOrderByRelationAggregateInput>
  shippingAddress?: InputMaybe<OrderAddressOrderByWithRelationInput>
  shippingAddressId?: InputMaybe<SortOrder>
  shippingCosts?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  totalDiscount?: InputMaybe<SortOrder>
  totalPriceExTax?: InputMaybe<SortOrder>
  totalPriceIncTax?: InputMaybe<SortOrder>
  totalTax?: InputMaybe<SortOrder>
  tourBooking?: InputMaybe<TourBookingOrderByWithRelationInput>
  tourBookingId?: InputMaybe<SortOrder>
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
  vouchers?: InputMaybe<OrderVoucherOrderByRelationAggregateInput>
}

export type OrderAddressOrderByWithRelationInput = {
  billingAddressOrder?: InputMaybe<OrderOrderByWithRelationInput>
  city?: InputMaybe<SortOrder>
  companyName?: InputMaybe<SortOrder>
  country?: InputMaybe<SortOrder>
  firstName?: InputMaybe<SortOrder>
  gender?: InputMaybe<SortOrder>
  houseNumber?: InputMaybe<SortOrder>
  houseNumberSuffix?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  postalCode?: InputMaybe<SortOrder>
  shippingAddressOrder?: InputMaybe<OrderOrderByWithRelationInput>
  state?: InputMaybe<SortOrder>
  street?: InputMaybe<SortOrder>
}

export type OrderItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PrivilegeOrderItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type TourBookingOrderByWithRelationInput = {
  client?: InputMaybe<SortOrder>
  clientName?: InputMaybe<SortOrder>
  code?: InputMaybe<SortOrder>
  countryOfOrigin?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  date?: InputMaybe<SortOrder>
  email?: InputMaybe<SortOrder>
  firstName?: InputMaybe<SortOrder>
  gender?: InputMaybe<SortOrder>
  groupType?: InputMaybe<SortOrder>
  hotel?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  localGuide?: InputMaybe<SortOrder>
  message?: InputMaybe<SortOrder>
  name?: InputMaybe<SortOrder>
  order?: InputMaybe<OrderOrderByWithRelationInput>
  persons?: InputMaybe<SortOrder>
  phone?: InputMaybe<SortOrder>
  prefferedLanguage?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  time?: InputMaybe<SortOrder>
  totalPrice?: InputMaybe<SortOrder>
  tourLeader?: InputMaybe<SortOrder>
  travelAgency?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type UserOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>
  activationKey?: InputMaybe<SortOrder>
  activityLogEntries?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>
  addresses?: InputMaybe<AddressOrderByRelationAggregateInput>
  cart?: InputMaybe<CartOrderByWithRelationInput>
  createdAt?: InputMaybe<SortOrder>
  CreatedForms?: InputMaybe<FormOrderByRelationAggregateInput>
  createdVouchers?: InputMaybe<VoucherOrderByRelationAggregateInput>
  dafUserId?: InputMaybe<SortOrder>
  email?: InputMaybe<SortOrder>
  files?: InputMaybe<FileOrderByRelationAggregateInput>
  firstName?: InputMaybe<SortOrder>
  FormSubmissions?: InputMaybe<FormSubmissionOrderByRelationAggregateInput>
  fullName?: InputMaybe<SortOrder>
  giftcards?: InputMaybe<GiftcardOrderByRelationAggregateInput>
  hash?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  lastLogin?: InputMaybe<SortOrder>
  lastName?: InputMaybe<SortOrder>
  leadResponses?: InputMaybe<LeadResponseOrderByRelationAggregateInput>
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>
  passwordReset?: InputMaybe<PasswordResetOrderByWithRelationInput>
  refreshKey?: InputMaybe<SortOrder>
  role?: InputMaybe<SortOrder>
  salt?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  wishlist?: InputMaybe<SortOrder>
  wishlists?: InputMaybe<UserWishlistOrderByRelationAggregateInput>
}

export type AddressOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type CartOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  items?: InputMaybe<CartItemOrderByRelationAggregateInput>
  privilegeItems?: InputMaybe<PrivilegeCartItemOrderByRelationAggregateInput>
  shippingCosts?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
  vouchers?: InputMaybe<CartVoucherOrderByRelationAggregateInput>
}

export type CartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PrivilegeCartItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type CartVoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type FormOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type VoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type FormSubmissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type GiftcardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PasswordResetOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>
  token?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
  validTill?: InputMaybe<SortOrder>
}

export type UserWishlistOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type OrderVoucherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type VoucherOrderByWithRelationInput = {
  activityLog?: InputMaybe<ActivityLogEntryOrderByRelationAggregateInput>
  code?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  createdBy?: InputMaybe<UserOrderByWithRelationInput>
  createdById?: InputMaybe<SortOrder>
  description?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  limit?: InputMaybe<SortOrder>
  limitToBrands?: InputMaybe<SortOrder>
  minimalOrderValue?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  used?: InputMaybe<SortOrder>
  validTill?: InputMaybe<SortOrder>
  value?: InputMaybe<SortOrder>
}

export enum VoucherStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Expired = "EXPIRED",
  Inactive = "INACTIVE",
  UsedUp = "USED_UP",
}

export type VouchersFilterInput = {
  query?: InputMaybe<Scalars["String"]>
  status?: InputMaybe<Array<InputMaybe<VoucherStatus>>>
  type?: InputMaybe<Array<InputMaybe<VoucherType>>>
  validTill?: InputMaybe<Scalars["Date"]>
}

export type QueryVouchersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  status?: InputMaybe<SortOrder>
  store?: InputMaybe<SortOrder>
  type?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
  validTill?: InputMaybe<SortOrder>
}

export type VouchersPayload = {
  __typename?: "VouchersPayload"
  items?: Maybe<Array<Maybe<Voucher>>>
  total?: Maybe<Scalars["Int"]>
}

export type Mutation = {
  __typename?: "Mutation"
  addAddress?: Maybe<Address>
  addArticleToCart?: Maybe<Cart>
  addGiftcard?: Maybe<Giftcard>
  addGiftcardToCart?: Maybe<Cart>
  addLeadMemo?: Maybe<Lead>
  addLeadResponse?: Maybe<Lead>
  addOrderMemo?: Maybe<Order>
  addPrivilegeToCart?: Maybe<Cart>
  addVoucher?: Maybe<Cart>
  adminAccountActivation?: Maybe<LoginPayload>
  adyenCreateCheckoutSession: AdyenCreateCheckoutSessionPaymentPayload
  cdcCompleteSocialLogin: Scalars["Boolean"]
  cdcLogin: Scalars["Boolean"]
  changeLeadStatus?: Maybe<Lead>
  changeOrderAddress?: Maybe<Order>
  changeOrderStatus?: Maybe<Order>
  /** This returns the full amount that is left on the giftcard */
  checkGiftCardBalance?: Maybe<Scalars["Int"]>
  /** Converts a cart to an order */
  checkout?: Maybe<Order>
  choicesAddArticleCombinationToCart?: Maybe<Cart>
  choicesAddArticleToCart?: Maybe<Cart>
  choicesAddGiftcardToCart?: Maybe<Cart>
  choicesWarrantySignup?: Maybe<Scalars["Boolean"]>
  clearCart?: Maybe<Cart>
  createAccount?: Maybe<Scalars["Boolean"]>
  createAdmin?: Maybe<User>
  createForm: Form
  createFormSubmission: FormSubmission
  createFormSubmissionsExport: Scalars["Boolean"]
  createVoucher?: Maybe<Voucher>
  deleteAddress?: Maybe<Scalars["Boolean"]>
  deleteAdmin?: Maybe<User>
  deleteCart?: Maybe<Scalars["Boolean"]>
  deleteForm: Scalars["Boolean"]
  deleteFormSubmission: Scalars["Boolean"]
  deleteGiftcard?: Maybe<Scalars["Boolean"]>
  deleteUserFile?: Maybe<Scalars["String"]>
  deleteVoucher?: Maybe<Voucher>
  downloadInvoice?: Maybe<Scalars["String"]>
  downloadUserFile?: Maybe<Scalars["String"]>
  exportOrders?: Maybe<Scalars["String"]>
  forwardLead?: Maybe<Lead>
  login?: Maybe<LoginPayload>
  newsletterSignup?: Maybe<Scalars["Boolean"]>
  orderAddGiftcard: Scalars["Boolean"]
  orderAddVoucher: Scalars["Boolean"]
  orderRemoveGiftcard: Scalars["Boolean"]
  orderRemoveVoucher: Scalars["Boolean"]
  orderVerifyFullyPaid: OrderVerifyFullyPaidPayload
  payment?: Maybe<Scalars["Json"]>
  paymentDetails?: Maybe<Scalars["Json"]>
  removeItemFromCart?: Maybe<Cart>
  removePrivilegeItemFromCart?: Maybe<Cart>
  removeVoucher?: Maybe<Cart>
  renewToken?: Maybe<LoginPayload>
  requestResetPassword?: Maybe<Scalars["Boolean"]>
  resetPassword?: Maybe<LoginPayload>
  returnOrderRequest?: Maybe<Scalars["Boolean"]>
  submitContactForm: Scalars["Boolean"]
  submitEventForm?: Maybe<Scalars["Boolean"]>
  submitPreOwnedWatch?: Maybe<Scalars["Boolean"]>
  syncDafManual?: Maybe<Order>
  syncMyParcelManual?: Maybe<Order>
  updateAddress?: Maybe<Address>
  updateAdmin?: Maybe<User>
  updateCartItemQuantity?: Maybe<Cart>
  updateCartItemSize?: Maybe<Cart>
  updateForm: Form
  updateNewsletterOptin?: Maybe<Scalars["Boolean"]>
  updateNewsletterPreferences?: Maybe<User>
  updatePartnerInfo?: Maybe<User>
  updatePassword?: Maybe<Scalars["Boolean"]>
  updatePreferences?: Maybe<Array<Maybe<DafPreferenceTypeItem>>>
  updateProfile?: Maybe<User>
  updateShippingCosts?: Maybe<Cart>
  updateUserWishlist?: Maybe<UserWishlist>
  updateVoucher?: Maybe<Voucher>
  uploadFileGetId?: Maybe<Scalars["String"]>
  uploadUserFile?: Maybe<File>
  /** This returns the full amount on the giftcard if the total cart amount is higher then the giftcard value, else it wil return the total giftcard value */
  validateGiftCard?: Maybe<Scalars["Int"]>
  /** This returns an array of applied giftcards and an accumulated total amount of applied giftcards */
  validateGiftCards?: Maybe<ValidateGiftcardsPayload>
  version: Scalars["String"]
}

export type MutationAddAddressArgs = {
  input: AddressInput
}

export type MutationAddArticleToCartArgs = {
  articleId: Scalars["String"]
  message?: InputMaybe<Scalars["String"]>
  quantity?: InputMaybe<Scalars["Int"]>
  reference: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
}

export type MutationAddGiftcardArgs = {
  input: GiftcardInput
}

export type MutationAddGiftcardToCartArgs = {
  amount: Scalars["Int"]
  quantity?: InputMaybe<Scalars["Int"]>
  reference: Scalars["String"]
}

export type MutationAddLeadMemoArgs = {
  leadId: Scalars["String"]
  memo: Scalars["String"]
}

export type MutationAddLeadResponseArgs = {
  id: Scalars["String"]
  message: Scalars["String"]
  subject: Scalars["String"]
}

export type MutationAddOrderMemoArgs = {
  memo: Scalars["String"]
  orderId: Scalars["Int"]
}

export type MutationAddPrivilegeToCartArgs = {
  input: AddPrivilegeToCartInput
}

export type MutationAddVoucherArgs = {
  code: Scalars["String"]
  reference: Scalars["String"]
}

export type MutationAdminAccountActivationArgs = {
  input: AdminAccountActivationInput
}

export type MutationAdyenCreateCheckoutSessionArgs = {
  input: AdyenCreateCheckoutSessionInput
}

export type MutationCdcCompleteSocialLoginArgs = {
  input: CdcCompleteSocialLoginInput
}

export type MutationCdcLoginArgs = {
  input: CdcLoginInput
}

export type MutationChangeLeadStatusArgs = {
  leadId: Scalars["String"]
  memo?: InputMaybe<Scalars["String"]>
  status: LeadStatus
}

export type MutationChangeOrderAddressArgs = {
  address: AddressInput
  orderId: Scalars["Int"]
  variant: ChangeOrderAddressType
}

export type MutationChangeOrderStatusArgs = {
  memo?: InputMaybe<Scalars["String"]>
  orderId: Scalars["Int"]
  status: OrderStatus
}

export type MutationCheckGiftCardBalanceArgs = {
  input: CheckGiftcardBalanceInput
}

export type MutationCheckoutArgs = {
  input?: InputMaybe<CheckoutInput>
  meta?: InputMaybe<CheckoutMetaInput>
  reference: Scalars["String"]
}

export type MutationChoicesAddArticleCombinationToCartArgs = {
  articleId: Scalars["String"]
  quantity?: InputMaybe<Scalars["Int"]>
  reference: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
}

export type MutationChoicesAddArticleToCartArgs = {
  articleId: Scalars["String"]
  message?: InputMaybe<Scalars["String"]>
  quantity?: InputMaybe<Scalars["Int"]>
  reference: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
}

export type MutationChoicesAddGiftcardToCartArgs = {
  amount: Scalars["Int"]
  quantity?: InputMaybe<Scalars["Int"]>
  reference: Scalars["String"]
}

export type MutationChoicesWarrantySignupArgs = {
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  newsletterSignup: Scalars["Boolean"]
  purchaseDate: Scalars["String"]
}

export type MutationClearCartArgs = {
  reference: Scalars["String"]
}

export type MutationCreateAccountArgs = {
  cartReference?: InputMaybe<Scalars["String"]>
  input: CreateAccountInput
  url?: InputMaybe<Scalars["String"]>
  wishlist?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
}

export type MutationCreateAdminArgs = {
  input: CreateAdminInput
}

export type MutationCreateFormArgs = {
  input: CreateFormInput
}

export type MutationCreateFormSubmissionArgs = {
  formId: Scalars["String"]
  input: CreateFormSubmissionInput
}

export type MutationCreateFormSubmissionsExportArgs = {
  formId: Scalars["String"]
}

export type MutationCreateVoucherArgs = {
  input: CreateVoucherInput
}

export type MutationDeleteAddressArgs = {
  id: Scalars["String"]
}

export type MutationDeleteAdminArgs = {
  id: Scalars["String"]
}

export type MutationDeleteCartArgs = {
  reference: Scalars["String"]
}

export type MutationDeleteFormArgs = {
  id: Scalars["String"]
}

export type MutationDeleteFormSubmissionArgs = {
  id: Scalars["String"]
}

export type MutationDeleteGiftcardArgs = {
  id: Scalars["String"]
}

export type MutationDeleteUserFileArgs = {
  id: Scalars["String"]
}

export type MutationDeleteVoucherArgs = {
  id: Scalars["String"]
}

export type MutationDownloadInvoiceArgs = {
  ref: Scalars["String"]
}

export type MutationDownloadUserFileArgs = {
  id: Scalars["String"]
}

export type MutationExportOrdersArgs = {
  input: ExportOrdersInput
}

export type MutationForwardLeadArgs = {
  input: ForwardLeadInput
}

export type MutationLoginArgs = {
  email: Scalars["String"]
  loginTo: LoginTo
  password: Scalars["String"]
}

export type MutationNewsletterSignupArgs = {
  input?: InputMaybe<NewsletterSignupInput>
}

export type MutationOrderAddGiftcardArgs = {
  id: Scalars["Int"]
  input: AddGiftcardToOrderGiftcardInput
}

export type MutationOrderAddVoucherArgs = {
  code: Scalars["String"]
  id: Scalars["Int"]
}

export type MutationOrderRemoveGiftcardArgs = {
  giftcardNumber: Scalars["String"]
  id: Scalars["Int"]
}

export type MutationOrderRemoveVoucherArgs = {
  code: Scalars["String"]
  id: Scalars["Int"]
}

export type MutationOrderVerifyFullyPaidArgs = {
  id: Scalars["Int"]
}

export type MutationPaymentArgs = {
  input: PaymentInput
}

export type MutationPaymentDetailsArgs = {
  input: PaymentDetailsInput
}

export type MutationRemoveItemFromCartArgs = {
  cartItemId: Scalars["String"]
  reference: Scalars["String"]
}

export type MutationRemovePrivilegeItemFromCartArgs = {
  input: RemovePrivilegeItemFromCartInput
}

export type MutationRemoveVoucherArgs = {
  code: Scalars["String"]
  reference: Scalars["String"]
}

export type MutationRenewTokenArgs = {
  authToken: Scalars["String"]
  refreshToken: Scalars["String"]
}

export type MutationRequestResetPasswordArgs = {
  email: Scalars["String"]
  url: Scalars["String"]
}

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"]
  token: Scalars["String"]
}

export type MutationReturnOrderRequestArgs = {
  input: ReturnOrderRequestInput
}

export type MutationSubmitContactFormArgs = {
  input: ContactFormInput
  newsletterSignup?: InputMaybe<Scalars["Boolean"]>
}

export type MutationSubmitEventFormArgs = {
  input: EventFormInput
}

export type MutationSubmitPreOwnedWatchArgs = {
  input: PreOwnedWatchInput
}

export type MutationSyncDafManualArgs = {
  id: Scalars["Int"]
}

export type MutationSyncMyParcelManualArgs = {
  id: Scalars["Int"]
}

export type MutationUpdateAddressArgs = {
  id: Scalars["String"]
  input: AddressInput
}

export type MutationUpdateAdminArgs = {
  id: Scalars["String"]
  input: UpdateAdminInput
}

export type MutationUpdateCartItemQuantityArgs = {
  cartItemId: Scalars["String"]
  quantity: Scalars["Int"]
  reference: Scalars["String"]
}

export type MutationUpdateCartItemSizeArgs = {
  cartItemId: Scalars["String"]
  reference: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
}

export type MutationUpdateFormArgs = {
  id: Scalars["String"]
  input: UpdateFormInput
}

export type MutationUpdateNewsletterOptinArgs = {
  input: UpdateNewsletterOptinInput
}

export type MutationUpdateNewsletterPreferencesArgs = {
  optins: Array<DafOptins>
}

export type MutationUpdatePartnerInfoArgs = {
  input: UpdatePartnerInfoInput
}

export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars["String"]
  newPassword: Scalars["String"]
}

export type MutationUpdatePreferencesArgs = {
  input: Array<UpdatePreferencesInput>
}

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput
}

export type MutationUpdateShippingCostsArgs = {
  countryCode: Scalars["String"]
  reference: Scalars["String"]
}

export type MutationUpdateUserWishlistArgs = {
  articleIds: Array<Scalars["String"]>
  id: Scalars["String"]
  status: UserWishlistStatus
}

export type MutationUpdateVoucherArgs = {
  id: Scalars["String"]
  input: UpdateVoucherInput
}

export type MutationUploadFileGetIdArgs = {
  upload: Scalars["Upload"]
}

export type MutationUploadUserFileArgs = {
  input: UploadFileInput
  upload: Scalars["Upload"]
}

export type MutationValidateGiftCardArgs = {
  input: ValidateGiftCardInput
  reference: Scalars["String"]
}

export type MutationValidateGiftCardsArgs = {
  input: Array<ValidateGiftCardsInput>
  reference: Scalars["String"]
}

export type AddressInput = {
  city: Scalars["String"]
  companyName?: InputMaybe<Scalars["String"]>
  country: Scalars["String"]
  firstName: Scalars["String"]
  gender: Gender
  houseNumber: Scalars["String"]
  houseNumberSuffix?: InputMaybe<Scalars["String"]>
  isBillingAddress?: InputMaybe<Scalars["Boolean"]>
  isShippingAddress?: InputMaybe<Scalars["Boolean"]>
  lastName: Scalars["String"]
  postalCode: Scalars["String"]
  state?: InputMaybe<Scalars["String"]>
  street: Scalars["String"]
}

export type GiftcardInput = {
  code: Scalars["String"]
  securityCode: Scalars["String"]
}

export type AddPrivilegeToCartInput = {
  imageSource: Scalars["String"]
  privilegeId: Scalars["String"]
  reference: Scalars["String"]
}

export type AdminAccountActivationInput = {
  activationKey: Scalars["String"]
  email: Scalars["String"]
  password: Scalars["String"]
}

export type LoginPayload = {
  __typename?: "LoginPayload"
  authToken?: Maybe<Scalars["String"]>
  refreshToken?: Maybe<Scalars["String"]>
  user?: Maybe<User>
}

export type AdyenCreateCheckoutSessionInput = {
  orderId: Scalars["Int"]
  returnUrl: Scalars["URL"]
}

export type AdyenCreateCheckoutSessionPaymentPayload = {
  __typename?: "AdyenCreateCheckoutSessionPaymentPayload"
  sessionData?: Maybe<Scalars["String"]>
  sessionId?: Maybe<Scalars["String"]>
}

export type CdcCompleteSocialLoginInput = {
  cartReference?: InputMaybe<Scalars["String"]>
  signatureTimestamp: Scalars["String"]
  UID: Scalars["String"]
  UIDSignature: Scalars["String"]
}

export type CdcLoginInput = {
  cartReference?: InputMaybe<Scalars["String"]>
  email: Scalars["EmailAddress"]
  password: Scalars["String"]
}

export enum ChangeOrderAddressType {
  Billing = "billing",
  Shipping = "shipping",
}

export type CheckGiftcardBalanceInput = {
  giftcardNumber: Scalars["String"]
  securityCode: Scalars["String"]
}

export type CheckoutInput = {
  billingAddress: AddressInput
  contact: CheckoutContactInput
  shippingAddress: AddressInput
}

export type CheckoutContactInput = {
  email: Scalars["String"]
  phone: Scalars["String"]
}

export type CheckoutMetaInput = {
  deliveryType?: InputMaybe<OrderDeliveryType>
  giftWrap?: InputMaybe<Scalars["Boolean"]>
  giftWrapText?: InputMaybe<Scalars["String"]>
  message?: InputMaybe<Scalars["String"]>
  newsletterSignup?: InputMaybe<Scalars["Boolean"]>
}

export type CreateAccountInput = {
  address: AddressInput
  /** Datestring formatted as YYYY-MM-DD */
  birthdate?: InputMaybe<Scalars["String"]>
  choicesNewsletterOptIn?: InputMaybe<Scalars["Boolean"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  gassanNewsletterOptIn?: InputMaybe<Scalars["Boolean"]>
  gender: Gender
  lastName: Scalars["String"]
  password: Scalars["String"]
  phone: Scalars["String"]
  /** Datestring formatted as YYYY-MM-DD */
  weddingdate?: InputMaybe<Scalars["String"]>
}

export type CreateAdminInput = {
  access: Array<UserAccess>
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
}

export type CreateFormInput = {
  adminEmailRecipient?: InputMaybe<Scalars["String"]>
  adminEmailSubject?: InputMaybe<Scalars["String"]>
  autoReplyTemplates?: InputMaybe<Scalars["JSON"]>
  customNewsletterText?: InputMaybe<Scalars["JSON"]>
  customNumberOfPersons?: InputMaybe<Scalars["Int"]>
  fields: Array<FormField>
  limitToOneSubmission?: InputMaybe<Scalars["Boolean"]>
  name: Scalars["String"]
  note?: InputMaybe<Scalars["String"]>
  selectableDates?: InputMaybe<Array<Scalars["String"]>>
  selectableTimes?: InputMaybe<Array<Scalars["String"]>>
  store: FormStore
}

export type CreateFormSubmissionInput = {
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  values: Scalars["JSON"]
}

export type CreateVoucherInput = {
  code: Scalars["String"]
  description: Scalars["String"]
  limit?: InputMaybe<Scalars["Int"]>
  limitToBrands?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
  status: VoucherStatus
  store: Store
  type: VoucherType
  validTill?: InputMaybe<Scalars["Date"]>
  value: Scalars["Int"]
}

export type ExportOrdersInput = {
  from?: InputMaybe<Scalars["String"]>
  till?: InputMaybe<Scalars["String"]>
}

export type ForwardLeadInput = {
  description: Scalars["String"]
  email: Scalars["String"]
  leadId: Scalars["String"]
}

export enum LoginTo {
  Account = "account",
  Admin = "admin",
}

export type NewsletterSignupInput = {
  birthdate?: InputMaybe<Scalars["String"]>
  brands?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
  choicesNewsletter?: InputMaybe<Scalars["Boolean"]>
  email: Scalars["String"]
  firstName: Scalars["String"]
  freeWhitepaper?: InputMaybe<Scalars["Boolean"]>
  gassanNewsletter?: InputMaybe<Scalars["Boolean"]>
  lastName: Scalars["String"]
  phone?: InputMaybe<Scalars["String"]>
  salutation?: InputMaybe<Scalars["String"]>
  /** Location where this user signed up */
  signedUpFrom?: InputMaybe<Scalars["String"]>
}

export type AddGiftcardToOrderGiftcardInput = {
  /** The amount to reserve in cents (EUR) */
  amount: Scalars["Int"]
  giftcardNumber: Scalars["String"]
  securityCode: Scalars["String"]
}

export type OrderVerifyFullyPaidPayload = {
  __typename?: "OrderVerifyFullyPaidPayload"
  fullyPaid: Scalars["Boolean"]
}

export type PaymentInput = {
  browserInfo?: InputMaybe<Scalars["Json"]>
  email: Scalars["String"]
  giftcards?: InputMaybe<Array<InputMaybe<PaymentGiftcardInput>>>
  orderId: Scalars["Int"]
  paymentMethod?: InputMaybe<Scalars["Json"]>
  paypalOrderId?: InputMaybe<Scalars["String"]>
  returnUrl: Scalars["String"]
  shopperIP?: InputMaybe<Scalars["String"]>
}

export type PaymentGiftcardInput = {
  /** The amount in cents */
  amount: Scalars["Int"]
  giftcardNumber: Scalars["String"]
  securityCode: Scalars["String"]
}

export type PaymentDetailsInput = {
  MD: Scalars["String"]
  PaRes: Scalars["String"]
  paymentData: Scalars["String"]
}

export type RemovePrivilegeItemFromCartInput = {
  privilegeCartItemId: Scalars["String"]
  reference: Scalars["String"]
}

export type ReturnOrderRequestInput = {
  email: Scalars["String"]
  items: Array<ReturnItem>
  orderId: Scalars["Int"]
}

export type ReturnItem = {
  id?: InputMaybe<Scalars["String"]>
  reason?: InputMaybe<Scalars["String"]>
}

export type ContactFormInput = {
  articleId?: InputMaybe<Scalars["String"]>
  brand?: InputMaybe<Scalars["String"]>
  contactPreference?: InputMaybe<ContactPreference>
  email: Scalars["String"]
  firstName: Scalars["String"]
  gender: Gender
  lastName: Scalars["String"]
  leadStore?: InputMaybe<LeadStore>
  location?: InputMaybe<Scalars["String"]>
  message?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  preferredDate?: InputMaybe<Scalars["String"]>
  preferredStore?: InputMaybe<Scalars["String"]>
  productName?: InputMaybe<Scalars["String"]>
  /** The location (mostly the URL) from which this is called. */
  referrer: Scalars["String"]
  /** The title of the page the form is send from */
  referrerTitle: Scalars["String"]
  store?: InputMaybe<Scalars["String"]>
  subject?: InputMaybe<Scalars["String"]>
  type: LeadType
  variant?: InputMaybe<ContactFormVariant>
}

export enum ContactPreference {
  Email = "EMAIL",
  Phone = "PHONE",
}

export enum ContactFormVariant {
  Gassan = "GASSAN",
  Rolex = "ROLEX",
}

export type EventFormInput = {
  city: Scalars["String"]
  country: Scalars["String"]
  dietaryRequirements: Scalars["String"]
  email: Scalars["String"]
  firstName: Scalars["String"]
  heardOfGassanVia: Scalars["String"]
  houseNumber: Scalars["String"]
  houseNumberSuffix: Scalars["String"]
  lastName: Scalars["String"]
  newsletter?: InputMaybe<Scalars["Boolean"]>
  note: Scalars["String"]
  numberOfPersons: Scalars["String"]
  phoneNumber: Scalars["String"]
  plazaBrilliantNews?: InputMaybe<Scalars["Boolean"]>
  postalCode: Scalars["String"]
  preferredDate: Scalars["String"]
  preferredTime: Scalars["String"]
  /** The location (mostly the URL) from which this is called. */
  referrer: Scalars["String"]
  street: Scalars["String"]
  title: Scalars["String"]
  toEmail: Scalars["Int"]
  urlSlug?: InputMaybe<Scalars["String"]>
}

export type PreOwnedWatchInput = {
  email: Scalars["String"]
  fileIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
  firstName: Scalars["String"]
  gender: Gender
  lastName: Scalars["String"]
  newsletterSignup?: InputMaybe<Scalars["Boolean"]>
  phone?: InputMaybe<Scalars["String"]>
  preOwnedAdditionalItems: LeadAdditionalItems
  preOwnedBrand: Scalars["String"]
  preOwnedExpectedPrice: Scalars["String"]
  preOwnedModel: Scalars["String"]
  preOwnedReference: Scalars["String"]
  preOwnedTradeType: LeadTradeType
  preOwnedYear: Scalars["String"]
}

export type UpdateAdminInput = {
  access?: InputMaybe<Array<InputMaybe<UserAccess>>>
  email?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
}

export type UpdateFormInput = {
  adminEmailRecipient?: InputMaybe<Scalars["String"]>
  adminEmailSubject?: InputMaybe<Scalars["String"]>
  autoReplyTemplates?: InputMaybe<Scalars["JSON"]>
  customNewsletterText?: InputMaybe<Scalars["JSON"]>
  customNumberOfPersons?: InputMaybe<Scalars["Int"]>
  fields?: InputMaybe<Array<FormField>>
  limitToOneSubmission?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  note?: InputMaybe<Scalars["String"]>
  selectableDates?: InputMaybe<Array<Scalars["String"]>>
  selectableTimes?: InputMaybe<Array<Scalars["String"]>>
  store: FormStore
}

export type UpdateNewsletterOptinInput = {
  email: Scalars["String"]
  optin100: Scalars["Boolean"]
  optin101: Scalars["Boolean"]
  optin102: Scalars["Boolean"]
  reason: Scalars["String"]
}

export enum DafOptins {
  Optin100 = "optin100",
  Optin101 = "optin101",
  Optin102 = "optin102",
  Optin103 = "optin103",
}

export type UpdatePartnerInfoInput = {
  birthdate?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  gender?: InputMaybe<Gender>
  lastName?: InputMaybe<Scalars["String"]>
}

export type UpdatePreferencesInput = {
  preference_type_description: Scalars["String"]
  preference_type_id: Scalars["Int"]
  preferences: Array<SinglePreferenceInput>
}

export type SinglePreferenceInput = {
  description: Scalars["String"]
  isSelected: Scalars["Boolean"]
  preference_no: Scalars["Int"]
  profile: Scalars["String"]
}

export type UpdateProfileInput = {
  birthdate?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  gender?: InputMaybe<Gender>
  lastName?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  ringSizeLeft?: InputMaybe<Scalars["Int"]>
  ringSizeRight?: InputMaybe<Scalars["Int"]>
  weddingDate?: InputMaybe<Scalars["String"]>
  wristSize?: InputMaybe<Scalars["Int"]>
}

export type UpdateVoucherInput = {
  description: Scalars["String"]
  limit?: InputMaybe<Scalars["Int"]>
  limitToBrands?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>
  minimalOrderValue?: InputMaybe<Scalars["Int"]>
  status: VoucherStatus
  store: Store
  type: VoucherType
  validTill?: InputMaybe<Scalars["Date"]>
  value: Scalars["Int"]
}

export type UploadFileInput = {
  name: Scalars["String"]
  type: FileType
}

export type ValidateGiftCardInput = {
  giftcardNumber: Scalars["String"]
  securityCode: Scalars["String"]
}

export type ValidateGiftCardsInput = {
  giftcardNumber: Scalars["String"]
  securityCode: Scalars["String"]
}

export type ValidateGiftcardsPayload = {
  __typename?: "ValidateGiftcardsPayload"
  appliedGiftcards?: Maybe<Array<Maybe<AppliedGiftcards>>>
  totalAmount?: Maybe<Scalars["Int"]>
}

export type AppliedGiftcards = {
  __typename?: "AppliedGiftcards"
  appliedAmount?: Maybe<Scalars["Int"]>
  giftcardNumber?: Maybe<Scalars["String"]>
  securityCode?: Maybe<Scalars["String"]>
}

export type CheckoutCustomerInput = {
  email: Scalars["String"]
  name: Scalars["String"]
}

export type CheckoutMeta = {
  __typename?: "CheckoutMeta"
  display_price?: Maybe<CheckoutMetaDisplayPrice>
}

export type CheckoutMetaDisplayPrice = {
  __typename?: "CheckoutMetaDisplayPrice"
  tax?: Maybe<CheckoutMetaPrice>
  with_tax?: Maybe<CheckoutMetaPrice>
  without_tax?: Maybe<CheckoutMetaPrice>
}

export type CheckoutMetaPrice = {
  __typename?: "CheckoutMetaPrice"
  amount?: Maybe<Scalars["Int"]>
  currency?: Maybe<Scalars["String"]>
  formatted?: Maybe<Scalars["String"]>
}

export type CheckoutPayload = {
  __typename?: "CheckoutPayload"
  id?: Maybe<Scalars["String"]>
  meta?: Maybe<CheckoutMeta>
  payment?: Maybe<Scalars["String"]>
  status?: Maybe<Scalars["String"]>
}

export type EnumStoreFilter = {
  equals?: InputMaybe<Store>
  in?: InputMaybe<Array<Store>>
  not?: InputMaybe<NestedEnumStoreFilter>
  notIn?: InputMaybe<Array<Store>>
}

export type NestedEnumStoreFilter = {
  equals?: InputMaybe<Store>
  in?: InputMaybe<Array<Store>>
  not?: InputMaybe<NestedEnumStoreFilter>
  notIn?: InputMaybe<Array<Store>>
}

export type EnumVoucherStatusFilter = {
  equals?: InputMaybe<VoucherStatus>
  in?: InputMaybe<Array<VoucherStatus>>
  not?: InputMaybe<NestedEnumVoucherStatusFilter>
  notIn?: InputMaybe<Array<VoucherStatus>>
}

export type NestedEnumVoucherStatusFilter = {
  equals?: InputMaybe<VoucherStatus>
  in?: InputMaybe<Array<VoucherStatus>>
  not?: InputMaybe<NestedEnumVoucherStatusFilter>
  notIn?: InputMaybe<Array<VoucherStatus>>
}

export type EnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>
  in?: InputMaybe<Array<VoucherType>>
  not?: InputMaybe<NestedEnumVoucherTypeFilter>
  notIn?: InputMaybe<Array<VoucherType>>
}

export type NestedEnumVoucherTypeFilter = {
  equals?: InputMaybe<VoucherType>
  in?: InputMaybe<Array<VoucherType>>
  not?: InputMaybe<NestedEnumVoucherTypeFilter>
  notIn?: InputMaybe<Array<VoucherType>>
}

export type LeadWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>
}

export type OrderWhereUniqueInput = {
  billingAddressId?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["Int"]>
  key?: InputMaybe<Scalars["String"]>
  shippingAddressId?: InputMaybe<Scalars["String"]>
  tourBookingId?: InputMaybe<Scalars["String"]>
}

export type QueryVouchersWhereInput = {
  id?: InputMaybe<StringFilter>
  status?: InputMaybe<EnumVoucherStatusFilter>
  store?: InputMaybe<EnumStoreFilter>
  type?: InputMaybe<EnumVoucherTypeFilter>
}

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
}

export type VoucherWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]>
  id?: InputMaybe<Scalars["String"]>
}

export type ArticleQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type ArticleQuery = {
  __typename?: "Query"
  choicesArticle?: {
    __typename?: "ChoicesArticle"
    id: string
    slug: string
    price: number
    name: string
    style?: string | null
    material?: string | null
    component?: string | null
    size?: string | null
    cut?: string | null
    substyle?: string | null
  } | null
}

export type ArticleCombinationQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type ArticleCombinationQuery = {
  __typename?: "Query"
  choicesArticleCombination?: {
    __typename?: "ChoicesArticleCombination"
    id: string
    slug: string
    product_group: string
    price: number
    name: string
    style: string
    size?: string | null
    material?: string | null
    baseMaterial?: string | null
    substyle?: string | null
    hasFixedComponent: boolean
    articles: Array<{
      __typename?: "ChoicesArticleCombinationArticle"
      id: string
      component: string
      quantity: number
    }>
    images: Array<{
      __typename?: "ChoicesArticleCombinationImage"
      path: string
    }>
  } | null
}

export type ArticlesQueryVariables = Exact<{
  filter: ChoicesArticlesFilter
  pagination: ChoicesArticlesPagination
}>

export type ArticlesQuery = {
  __typename?: "Query"
  choicesArticles?: {
    __typename?: "ChoicesArticlesPayload"
    total: number
    fixedFilters: any
    dynamicFilters: any
    items: Array<{
      __typename?: "ChoicesArticle"
      id: string
      slug: string
      name: string
      price: number
      material?: string | null
      eancode?: string | null
      size?: string | null
      component?: string | null
      style?: string | null
      substyle?: string | null
    }>
  } | null
}

export type ArticleCombinationsQueryVariables = Exact<{
  filter: ChoicesArticleCombinationsFilter
  pagination: ChoicesArticleCombinationsPagination
}>

export type ArticleCombinationsQuery = {
  __typename?: "Query"
  choicesArticleCombinations?: {
    __typename?: "ChoicesArticleCombinationsPayload"
    total: number
    fixedFilters: any
    dynamicFilters: any
    items: Array<{
      __typename?: "ChoicesArticleCombination"
      id: string
      combi_no: number
      seq_nr?: string | null
      slug: string
      size?: string | null
      name: string
      material?: string | null
      gemstone: string
      eancode?: string | null
      product_group: string
      price: number
      articles: Array<{
        __typename?: "ChoicesArticleCombinationArticle"
        id: string
        component: string
        price: number
      }>
      images: Array<{
        __typename?: "ChoicesArticleCombinationImage"
        path: string
        position: number
      }>
    }>
  } | null
}

export type ThemeProfileCollectionQueryVariables = Exact<{
  themeProfile: ChoicesThemeProfileCodeFilter
  pagination: ChoicesArticleCombinationsPagination
}>

export type ThemeProfileCollectionQuery = {
  __typename?: "Query"
  choicesArticleCombinations?: {
    __typename?: "ChoicesArticleCombinationsPayload"
    total: number
    items: Array<{
      __typename?: "ChoicesArticleCombination"
      id: string
      combi_no: number
      product_group: string
      seq_nr?: string | null
      name: string
      slug: string
      price: number
      images: Array<{
        __typename?: "ChoicesArticleCombinationImage"
        path: string
      }>
    }>
  } | null
}

export type ArticlesByIdQueryVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"]
}>

export type ArticlesByIdQuery = {
  __typename?: "Query"
  choicesArticlesById?: Array<{
    __typename?: "ChoicesArticle"
    id: string
    price: number
    component?: string | null
  }> | null
}

export type ArticleCombinationsByIdQueryVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"]
}>

export type ArticleCombinationsByIdQuery = {
  __typename?: "Query"
  choicesArticleCombinationsById?: Array<{
    __typename?: "ChoicesArticleCombination"
    id: string
    product_group: string
    style: string
    size?: string | null
    price: number
    name: string
    slug: string
    substyle?: string | null
    material?: string | null
    articles: Array<{
      __typename?: "ChoicesArticleCombinationArticle"
      id: string
      component: string
      price: number
      quantity: number
    }>
    images: Array<{
      __typename?: "ChoicesArticleCombinationImage"
      path: string
    }>
  }> | null
}

export type RelatedCombinationsQueryVariables = Exact<{
  filter: ChoicesRelatedCombinationsFilter
  pagination: ChoicesRelatedCombinationsPagination
}>

export type RelatedCombinationsQuery = {
  __typename?: "Query"
  choicesRelatedCombinations?: {
    __typename?: "ChoicesRelatedCombinationsPayload"
    total: number
    filters: {
      __typename?: "ChoicesRelatedCombinationFilters"
      productGroup: Array<{
        __typename?: "ChoicesRelatedCombinationFiltersItem"
        name: string
        total: number
      }>
      size: Array<{
        __typename?: "ChoicesRelatedCombinationFiltersItem"
        name: string
        total: number
      }>
      style: Array<{
        __typename?: "ChoicesRelatedCombinationFiltersItem"
        name: string
        total: number
      }>
    }
    items: Array<{
      __typename?: "ChoicesRelatedCombination"
      id: string
      productGroup: string
      style: string
      size: string
      combinations: Array<{
        __typename?: "ChoicesArticleCombination"
        id: string
        combi_no: number
        seq_nr?: string | null
        product_group: string
        name: string
        eancode?: string | null
        slug: string
        collection: string
        style: string
        baseMaterial?: string | null
        substyle?: string | null
        size?: string | null
        material?: string | null
        hasFixedComponent: boolean
        gemstone: string
        price: number
        articles: Array<{
          __typename?: "ChoicesArticleCombinationArticle"
          id: string
          component: string
          price: number
        }>
        images: Array<{
          __typename?: "ChoicesArticleCombinationImage"
          path: string
        }>
      }>
    }>
  } | null
}

export type RelatedCombinationQueryVariables = Exact<{
  id: Scalars["String"]
}>

export type RelatedCombinationQuery = {
  __typename?: "Query"
  choicesRelatedCombination?: {
    __typename?: "ChoicesRelatedCombination"
    id: string
    productGroup: string
    style: string
    size: string
    combinations: Array<{
      __typename?: "ChoicesArticleCombination"
      id: string
      combi_no: number
      seq_nr?: string | null
      product_group: string
      name: string
      eancode?: string | null
      slug: string
      collection: string
      style: string
      baseMaterial?: string | null
      substyle?: string | null
      size?: string | null
      material?: string | null
      hasFixedComponent: boolean
      gemstone: string
      price: number
      articles: Array<{
        __typename?: "ChoicesArticleCombinationArticle"
        id: string
        component: string
        price: number
      }>
      images: Array<{
        __typename?: "ChoicesArticleCombinationImage"
        path: string
      }>
    }>
  } | null
}

export type CartFragmentFragment = {
  __typename?: "Cart"
  id: string
  totalTax?: number | null
  totalPriceExTax?: number | null
  totalPriceIncTax?: number | null
  totalItems?: number | null
  shippingCosts: number
  vouchers: Array<{
    __typename?: "CartVoucher"
    id: string
    type: VoucherType
    code: string
    value: number
  }>
  items?: Array<{
    __typename?: "CartItem"
    id: string
    quantity: number
    unitPrice: number
    totalPrice?: number | null
    description: string
    message?: string | null
    articleId: string
    size?: string | null
    choicesArticle?: any | null
    choicesArticleCombination?: any | null
  } | null> | null
}

export type ChoicesAddGiftcardToCartMutationVariables = Exact<{
  reference: Scalars["String"]
  amount: Scalars["Int"]
  quantity?: InputMaybe<Scalars["Int"]>
}>

export type ChoicesAddGiftcardToCartMutation = {
  __typename?: "Mutation"
  choicesAddGiftcardToCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type AddArticleToCartMutationVariables = Exact<{
  reference: Scalars["String"]
  articleId: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
  message?: InputMaybe<Scalars["String"]>
  quantity?: InputMaybe<Scalars["Int"]>
}>

export type AddArticleToCartMutation = {
  __typename?: "Mutation"
  choicesAddArticleToCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type AddArticleCombinationToCartMutationVariables = Exact<{
  reference: Scalars["String"]
  articleId: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
  quantity?: InputMaybe<Scalars["Int"]>
}>

export type AddArticleCombinationToCartMutation = {
  __typename?: "Mutation"
  choicesAddArticleCombinationToCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type CartQueryVariables = Exact<{
  reference?: InputMaybe<Scalars["String"]>
}>

export type CartQuery = {
  __typename?: "Query"
  choicesCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type RemoveItemFromCartMutationVariables = Exact<{
  reference: Scalars["String"]
  cartItemId: Scalars["String"]
}>

export type RemoveItemFromCartMutation = {
  __typename?: "Mutation"
  removeItemFromCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type UpdateCartItemQuantityMutationVariables = Exact<{
  reference: Scalars["String"]
  cartItemId: Scalars["String"]
  quantity: Scalars["Int"]
}>

export type UpdateCartItemQuantityMutation = {
  __typename?: "Mutation"
  updateCartItemQuantity?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type UpdateCartItemSizeMutationVariables = Exact<{
  reference: Scalars["String"]
  cartItemId: Scalars["String"]
  size?: InputMaybe<Scalars["String"]>
}>

export type UpdateCartItemSizeMutation = {
  __typename?: "Mutation"
  updateCartItemSize?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type ClearCartMutationVariables = Exact<{
  reference: Scalars["String"]
}>

export type ClearCartMutation = {
  __typename?: "Mutation"
  clearCart?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type AddVoucherMutationVariables = Exact<{
  reference: Scalars["String"]
  code: Scalars["String"]
}>

export type AddVoucherMutation = {
  __typename?: "Mutation"
  addVoucher?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type RemoveVoucherMutationVariables = Exact<{
  reference: Scalars["String"]
  code: Scalars["String"]
}>

export type RemoveVoucherMutation = {
  __typename?: "Mutation"
  removeVoucher?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type ValidateGiftCardsMutationVariables = Exact<{
  reference: Scalars["String"]
  input: Array<ValidateGiftCardsInput> | ValidateGiftCardsInput
}>

export type ValidateGiftCardsMutation = {
  __typename?: "Mutation"
  validateGiftCards?: {
    __typename?: "ValidateGiftcardsPayload"
    totalAmount?: number | null
    appliedGiftcards?: Array<{
      __typename?: "AppliedGiftcards"
      giftcardNumber?: string | null
      securityCode?: string | null
      appliedAmount?: number | null
    } | null> | null
  } | null
}

export type UpdateShippingCostsMutationVariables = Exact<{
  reference: Scalars["String"]
  countryCode: Scalars["String"]
}>

export type UpdateShippingCostsMutation = {
  __typename?: "Mutation"
  updateShippingCosts?: {
    __typename?: "Cart"
    id: string
    totalTax?: number | null
    totalPriceExTax?: number | null
    totalPriceIncTax?: number | null
    totalItems?: number | null
    shippingCosts: number
    vouchers: Array<{
      __typename?: "CartVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "CartItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type CheckoutMutationVariables = Exact<{
  reference: Scalars["String"]
  input: CheckoutInput
}>

export type CheckoutMutation = {
  __typename?: "Mutation"
  checkout?: {
    __typename?: "Order"
    id: number
    totalPriceIncTax: number
  } | null
}

export type PaymentMethodsQueryVariables = Exact<{
  amount: Scalars["Int"]
}>

export type PaymentMethodsQuery = {
  __typename?: "Query"
  paymentMethods?: any | null
}

export type NewsletterSignupMutationVariables = Exact<{
  input: NewsletterSignupInput
}>

export type NewsletterSignupMutation = {
  __typename?: "Mutation"
  newsletterSignup?: boolean | null
}

export type PaymentMutationVariables = Exact<{
  input: PaymentInput
}>

export type PaymentMutation = { __typename?: "Mutation"; payment?: any | null }

export type VerifyAdyenPaymentQueryVariables = Exact<{
  payload: Scalars["String"]
}>

export type VerifyAdyenPaymentQuery = {
  __typename?: "Query"
  verifyAdyenPayment?: boolean | null
}

export type PaymentDetailsMutationVariables = Exact<{
  input: PaymentDetailsInput
}>

export type PaymentDetailsMutation = {
  __typename?: "Mutation"
  paymentDetails?: any | null
}

export type PostalCodeLookupQueryVariables = Exact<{
  postalCode: Scalars["String"]
  houseNumber: Scalars["Int"]
}>

export type PostalCodeLookupQuery = {
  __typename?: "Query"
  postalCodeLookup?: {
    __typename?: "PostalCodeLookupPayload"
    id?: string | null
    postcode?: string | null
    number?: number | null
    street?: string | null
    city?: string | null
  } | null
}

export type AdyenCreateCheckoutSessionMutationVariables = Exact<{
  input: AdyenCreateCheckoutSessionInput
}>

export type AdyenCreateCheckoutSessionMutation = {
  __typename?: "Mutation"
  adyenCreateCheckoutSession: {
    __typename?: "AdyenCreateCheckoutSessionPaymentPayload"
    sessionId?: string | null
    sessionData?: string | null
  }
}

export type OrderVerifyFullyPaidMutationVariables = Exact<{
  orderId: Scalars["Int"]
}>

export type OrderVerifyFullyPaidMutation = {
  __typename?: "Mutation"
  orderVerifyFullyPaid: {
    __typename?: "OrderVerifyFullyPaidPayload"
    fullyPaid: boolean
  }
}

export type OrderAddVoucherMutationVariables = Exact<{
  orderId: Scalars["Int"]
  voucherCode: Scalars["String"]
}>

export type OrderAddVoucherMutation = {
  __typename?: "Mutation"
  orderAddVoucher: boolean
}

export type OrderAddGiftcardMutationVariables = Exact<{
  orderId: Scalars["Int"]
  input: AddGiftcardToOrderGiftcardInput
}>

export type OrderAddGiftcardMutation = {
  __typename?: "Mutation"
  orderAddGiftcard: boolean
}

export type OrderRemoveGiftcardMutationVariables = Exact<{
  orderId: Scalars["Int"]
  giftcardNumber: Scalars["String"]
}>

export type OrderRemoveGiftcardMutation = {
  __typename?: "Mutation"
  orderRemoveGiftcard: boolean
}

export type OrderRemoveVoucherMutationVariables = Exact<{
  orderId: Scalars["Int"]
  code: Scalars["String"]
}>

export type OrderRemoveVoucherMutation = {
  __typename?: "Mutation"
  orderRemoveVoucher: boolean
}

export type ValidateGiftCardMutationVariables = Exact<{
  reference: Scalars["String"]
  input: ValidateGiftCardInput
}>

export type ValidateGiftCardMutation = {
  __typename?: "Mutation"
  validateGiftCard?: number | null
}

export type OrderFieldsFragment = {
  __typename?: "Order"
  id: number
  status: OrderStatus
  contactEmail: string
  contactPhone: string
  totalPriceIncTax: number
  totalPriceExTax: number
  totalTax: number
  shippingCosts: number
  createdAt: any
  vouchers: Array<{
    __typename?: "OrderVoucher"
    id: string
    type: VoucherType
    code: string
    value: number
  }>
  items?: Array<{
    __typename?: "OrderItem"
    id: string
    quantity: number
    unitPrice: number
    totalPrice?: number | null
    description: string
    message?: string | null
    articleId: string
    size?: string | null
    choicesArticle?: any | null
    choicesArticleCombination?: any | null
  } | null> | null
  shippingAddress: {
    __typename?: "OrderAddress"
    firstName: string
    lastName: string
  }
}

export type OrderByIdAndEmailQueryVariables = Exact<{
  id: Scalars["Int"]
  email: Scalars["String"]
}>

export type OrderByIdAndEmailQuery = {
  __typename?: "Query"
  orderByIdAndEmail?: {
    __typename?: "Order"
    deliveryType: OrderDeliveryType
    id: number
    status: OrderStatus
    contactEmail: string
    contactPhone: string
    totalPriceIncTax: number
    totalPriceExTax: number
    totalTax: number
    shippingCosts: number
    createdAt: any
    shippingAddress: {
      __typename?: "OrderAddress"
      id: string
      gender: Gender
      companyName?: string | null
      firstName: string
      lastName: string
      street: string
      houseNumber: string
      houseNumberSuffix?: string | null
      postalCode: string
      city: string
      country: string
    }
    billingAddress: {
      __typename?: "OrderAddress"
      id: string
      gender: Gender
      companyName?: string | null
      firstName: string
      lastName: string
      street: string
      houseNumber: string
      houseNumberSuffix?: string | null
      postalCode: string
      city: string
      country: string
    }
    transactions: Array<{
      __typename?: "Transaction"
      type: TransactionType
      paymentMethod?: string | null
      reference?: string | null
      amount: number
    }>
    vouchers: Array<{
      __typename?: "OrderVoucher"
      id: string
      type: VoucherType
      code: string
      value: number
    }>
    items?: Array<{
      __typename?: "OrderItem"
      id: string
      quantity: number
      unitPrice: number
      totalPrice?: number | null
      description: string
      message?: string | null
      articleId: string
      size?: string | null
      choicesArticle?: any | null
      choicesArticleCombination?: any | null
    } | null> | null
  } | null
}

export type ReturnOrderRequestMutationVariables = Exact<{
  input: ReturnOrderRequestInput
}>

export type ReturnOrderRequestMutation = {
  __typename?: "Mutation"
  returnOrderRequest?: boolean | null
}

export type ChoicesWarrantySignupMutationVariables = Exact<{
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  email: Scalars["String"]
  purchaseDate: Scalars["String"]
  newsletterSignup: Scalars["Boolean"]
}>

export type ChoicesWarrantySignupMutation = {
  __typename?: "Mutation"
  choicesWarrantySignup?: boolean | null
}

export const CartFragmentFragmentDoc = gql`
  fragment CartFragment on Cart {
    id
    totalTax
    totalPriceExTax
    totalPriceIncTax
    totalItems
    shippingCosts
    vouchers {
      id
      type
      code
      value
    }
    items {
      id
      quantity
      unitPrice
      totalPrice
      description
      message
      articleId
      size
      choicesArticle
      choicesArticleCombination
    }
  }
`
export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    id
    status
    contactEmail
    contactPhone
    totalPriceIncTax
    totalPriceExTax
    totalTax
    shippingCosts
    createdAt
    vouchers {
      id
      type
      code
      value
    }
    items {
      id
      quantity
      unitPrice
      totalPrice
      description
      message
      articleId
      size
      choicesArticle
      choicesArticleCombination
    }
    shippingAddress {
      firstName
      lastName
    }
  }
`
export const ArticleDocument = gql`
  query Article($id: String!) {
    choicesArticle(id: $id) {
      id
      slug
      price
      name
      style
      material
      component
      size
      cut
      substyle
    }
  }
`
export const ArticleCombinationDocument = gql`
  query ArticleCombination($id: String!) {
    choicesArticleCombination(id: $id) {
      id
      slug
      product_group
      price
      name
      style
      size
      material
      baseMaterial
      substyle
      hasFixedComponent
      articles {
        id
        component
        quantity
      }
      images {
        path
      }
    }
  }
`
export const ArticlesDocument = gql`
  query Articles(
    $filter: ChoicesArticlesFilter!
    $pagination: ChoicesArticlesPagination!
  ) {
    choicesArticles(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        slug
        name
        price
        material
        eancode
        size
        component
        style
        substyle
      }
      fixedFilters
      dynamicFilters
    }
  }
`
export const ArticleCombinationsDocument = gql`
  query ArticleCombinations(
    $filter: ChoicesArticleCombinationsFilter!
    $pagination: ChoicesArticleCombinationsPagination!
  ) {
    choicesArticleCombinations(filter: $filter, pagination: $pagination) {
      total
      items {
        id
        combi_no
        seq_nr
        slug
        size
        name
        material
        gemstone
        eancode
        product_group
        price
        articles {
          id
          component
          price
        }
        images {
          path
          position
        }
      }
      fixedFilters
      dynamicFilters
    }
  }
`
export const ThemeProfileCollectionDocument = gql`
  query ThemeProfileCollection(
    $themeProfile: ChoicesThemeProfileCodeFilter!
    $pagination: ChoicesArticleCombinationsPagination!
  ) {
    choicesArticleCombinations(
      filter: { themeProfile: $themeProfile }
      pagination: $pagination
    ) {
      total
      items {
        id
        combi_no
        product_group
        seq_nr
        name
        slug
        price
        images {
          path
        }
      }
    }
  }
`
export const ArticlesByIdDocument = gql`
  query ArticlesById($ids: [String!]!) {
    choicesArticlesById(ids: $ids) {
      id
      price
      component
    }
  }
`
export const ArticleCombinationsByIdDocument = gql`
  query ArticleCombinationsById($ids: [String!]!) {
    choicesArticleCombinationsById(ids: $ids) {
      id
      product_group
      style
      size
      price
      name
      slug
      substyle
      material
      articles {
        id
        component
        price
        quantity
      }
      images {
        path
      }
    }
  }
`
export const RelatedCombinationsDocument = gql`
  query RelatedCombinations(
    $filter: ChoicesRelatedCombinationsFilter!
    $pagination: ChoicesRelatedCombinationsPagination!
  ) {
    choicesRelatedCombinations(filter: $filter, pagination: $pagination) {
      total
      filters {
        productGroup {
          name
          total
        }
        size {
          name
          total
        }
        style {
          name
          total
        }
      }
      items {
        id
        productGroup
        style
        size
        combinations {
          id
          combi_no
          seq_nr
          product_group
          name
          eancode
          slug
          collection
          style
          baseMaterial
          substyle
          size
          material
          hasFixedComponent
          gemstone
          price
          articles {
            id
            component
            price
          }
          images {
            path
          }
        }
      }
    }
  }
`
export const RelatedCombinationDocument = gql`
  query RelatedCombination($id: String!) {
    choicesRelatedCombination(id: $id) {
      id
      productGroup
      style
      size
      combinations {
        id
        combi_no
        seq_nr
        product_group
        name
        eancode
        slug
        collection
        style
        baseMaterial
        substyle
        size
        material
        hasFixedComponent
        gemstone
        price
        articles {
          id
          component
          price
        }
        images {
          path
        }
      }
    }
  }
`
export const ChoicesAddGiftcardToCartDocument = gql`
  mutation ChoicesAddGiftcardToCart(
    $reference: String!
    $amount: Int!
    $quantity: Int
  ) {
    choicesAddGiftcardToCart(
      reference: $reference
      amount: $amount
      quantity: $quantity
    ) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const AddArticleToCartDocument = gql`
  mutation AddArticleToCart(
    $reference: String!
    $articleId: String!
    $size: String
    $message: String
    $quantity: Int
  ) {
    choicesAddArticleToCart(
      reference: $reference
      articleId: $articleId
      size: $size
      message: $message
      quantity: $quantity
    ) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const AddArticleCombinationToCartDocument = gql`
  mutation AddArticleCombinationToCart(
    $reference: String!
    $articleId: String!
    $size: String
    $quantity: Int
  ) {
    choicesAddArticleCombinationToCart(
      reference: $reference
      articleId: $articleId
      size: $size
      quantity: $quantity
    ) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const CartDocument = gql`
  query Cart($reference: String) {
    choicesCart(reference: $reference) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const RemoveItemFromCartDocument = gql`
  mutation RemoveItemFromCart($reference: String!, $cartItemId: String!) {
    removeItemFromCart(reference: $reference, cartItemId: $cartItemId) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const UpdateCartItemQuantityDocument = gql`
  mutation UpdateCartItemQuantity(
    $reference: String!
    $cartItemId: String!
    $quantity: Int!
  ) {
    updateCartItemQuantity(
      reference: $reference
      cartItemId: $cartItemId
      quantity: $quantity
    ) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const UpdateCartItemSizeDocument = gql`
  mutation UpdateCartItemSize(
    $reference: String!
    $cartItemId: String!
    $size: String
  ) {
    updateCartItemSize(
      reference: $reference
      cartItemId: $cartItemId
      size: $size
    ) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const ClearCartDocument = gql`
  mutation ClearCart($reference: String!) {
    clearCart(reference: $reference) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const AddVoucherDocument = gql`
  mutation AddVoucher($reference: String!, $code: String!) {
    addVoucher(reference: $reference, code: $code) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const RemoveVoucherDocument = gql`
  mutation RemoveVoucher($reference: String!, $code: String!) {
    removeVoucher(reference: $reference, code: $code) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const ValidateGiftCardsDocument = gql`
  mutation ValidateGiftCards(
    $reference: String!
    $input: [ValidateGiftCardsInput!]!
  ) {
    validateGiftCards(reference: $reference, input: $input) {
      appliedGiftcards {
        giftcardNumber
        securityCode
        appliedAmount
      }
      totalAmount
    }
  }
`
export const UpdateShippingCostsDocument = gql`
  mutation UpdateShippingCosts($reference: String!, $countryCode: String!) {
    updateShippingCosts(reference: $reference, countryCode: $countryCode) {
      ...CartFragment
    }
  }
  ${CartFragmentFragmentDoc}
`
export const CheckoutDocument = gql`
  mutation Checkout($reference: String!, $input: CheckoutInput!) {
    checkout(reference: $reference, input: $input, meta: { giftWrap: false }) {
      id
      totalPriceIncTax
    }
  }
`
export const PaymentMethodsDocument = gql`
  query PaymentMethods($amount: Int!) {
    paymentMethods(amount: $amount)
  }
`
export const NewsletterSignupDocument = gql`
  mutation NewsletterSignup($input: NewsletterSignupInput!) {
    newsletterSignup(input: $input)
  }
`
export const PaymentDocument = gql`
  mutation Payment($input: PaymentInput!) {
    payment(input: $input)
  }
`
export const VerifyAdyenPaymentDocument = gql`
  query VerifyAdyenPayment($payload: String!) {
    verifyAdyenPayment(payload: $payload)
  }
`
export const PaymentDetailsDocument = gql`
  mutation PaymentDetails($input: PaymentDetailsInput!) {
    paymentDetails(input: $input)
  }
`
export const PostalCodeLookupDocument = gql`
  query PostalCodeLookup($postalCode: String!, $houseNumber: Int!) {
    postalCodeLookup(postalCode: $postalCode, houseNumber: $houseNumber) {
      id
      postcode
      number
      street
      city
    }
  }
`
export const AdyenCreateCheckoutSessionDocument = gql`
  mutation AdyenCreateCheckoutSession(
    $input: AdyenCreateCheckoutSessionInput!
  ) {
    adyenCreateCheckoutSession(input: $input) {
      sessionId
      sessionData
    }
  }
`
export const OrderVerifyFullyPaidDocument = gql`
  mutation OrderVerifyFullyPaid($orderId: Int!) {
    orderVerifyFullyPaid(id: $orderId) {
      fullyPaid
    }
  }
`
export const OrderAddVoucherDocument = gql`
  mutation OrderAddVoucher($orderId: Int!, $voucherCode: String!) {
    orderAddVoucher(id: $orderId, code: $voucherCode)
  }
`
export const OrderAddGiftcardDocument = gql`
  mutation OrderAddGiftcard(
    $orderId: Int!
    $input: AddGiftcardToOrderGiftcardInput!
  ) {
    orderAddGiftcard(id: $orderId, input: $input)
  }
`
export const OrderRemoveGiftcardDocument = gql`
  mutation OrderRemoveGiftcard($orderId: Int!, $giftcardNumber: String!) {
    orderRemoveGiftcard(id: $orderId, giftcardNumber: $giftcardNumber)
  }
`
export const OrderRemoveVoucherDocument = gql`
  mutation OrderRemoveVoucher($orderId: Int!, $code: String!) {
    orderRemoveVoucher(id: $orderId, code: $code)
  }
`
export const ValidateGiftCardDocument = gql`
  mutation ValidateGiftCard(
    $reference: String!
    $input: ValidateGiftCardInput!
  ) {
    validateGiftCard(reference: $reference, input: $input)
  }
`
export const OrderByIdAndEmailDocument = gql`
  query OrderByIdAndEmail($id: Int!, $email: String!) {
    orderByIdAndEmail(id: $id, email: $email) {
      ...OrderFields
      deliveryType
      shippingAddress {
        id
        gender
        companyName
        firstName
        lastName
        street
        houseNumber
        houseNumberSuffix
        postalCode
        city
        country
      }
      billingAddress {
        id
        gender
        companyName
        firstName
        lastName
        street
        houseNumber
        houseNumberSuffix
        postalCode
        city
        country
      }
      transactions {
        type
        paymentMethod
        reference
        amount
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`
export const ReturnOrderRequestDocument = gql`
  mutation ReturnOrderRequest($input: ReturnOrderRequestInput!) {
    returnOrderRequest(input: $input)
  }
`
export const ChoicesWarrantySignupDocument = gql`
  mutation ChoicesWarrantySignup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $purchaseDate: String!
    $newsletterSignup: Boolean!
  ) {
    choicesWarrantySignup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      purchaseDate: $purchaseDate
      newsletterSignup: $newsletterSignup
    )
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    Article(
      variables: ArticleQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticleQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticleQuery>(ArticleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Article",
        "query"
      )
    },
    ArticleCombination(
      variables: ArticleCombinationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticleCombinationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticleCombinationQuery>(
            ArticleCombinationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ArticleCombination",
        "query"
      )
    },
    Articles(
      variables: ArticlesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticlesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticlesQuery>(ArticlesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Articles",
        "query"
      )
    },
    ArticleCombinations(
      variables: ArticleCombinationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticleCombinationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticleCombinationsQuery>(
            ArticleCombinationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ArticleCombinations",
        "query"
      )
    },
    ThemeProfileCollection(
      variables: ThemeProfileCollectionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ThemeProfileCollectionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ThemeProfileCollectionQuery>(
            ThemeProfileCollectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ThemeProfileCollection",
        "query"
      )
    },
    ArticlesById(
      variables: ArticlesByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticlesByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticlesByIdQuery>(ArticlesByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ArticlesById",
        "query"
      )
    },
    ArticleCombinationsById(
      variables: ArticleCombinationsByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArticleCombinationsByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArticleCombinationsByIdQuery>(
            ArticleCombinationsByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ArticleCombinationsById",
        "query"
      )
    },
    RelatedCombinations(
      variables: RelatedCombinationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RelatedCombinationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RelatedCombinationsQuery>(
            RelatedCombinationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RelatedCombinations",
        "query"
      )
    },
    RelatedCombination(
      variables: RelatedCombinationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RelatedCombinationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RelatedCombinationQuery>(
            RelatedCombinationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RelatedCombination",
        "query"
      )
    },
    ChoicesAddGiftcardToCart(
      variables: ChoicesAddGiftcardToCartMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ChoicesAddGiftcardToCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChoicesAddGiftcardToCartMutation>(
            ChoicesAddGiftcardToCartDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ChoicesAddGiftcardToCart",
        "mutation"
      )
    },
    AddArticleToCart(
      variables: AddArticleToCartMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddArticleToCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddArticleToCartMutation>(
            AddArticleToCartDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "AddArticleToCart",
        "mutation"
      )
    },
    AddArticleCombinationToCart(
      variables: AddArticleCombinationToCartMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddArticleCombinationToCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddArticleCombinationToCartMutation>(
            AddArticleCombinationToCartDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "AddArticleCombinationToCart",
        "mutation"
      )
    },
    Cart(
      variables?: CartQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CartQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CartQuery>(CartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Cart",
        "query"
      )
    },
    RemoveItemFromCart(
      variables: RemoveItemFromCartMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RemoveItemFromCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveItemFromCartMutation>(
            RemoveItemFromCartDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RemoveItemFromCart",
        "mutation"
      )
    },
    UpdateCartItemQuantity(
      variables: UpdateCartItemQuantityMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateCartItemQuantityMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCartItemQuantityMutation>(
            UpdateCartItemQuantityDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateCartItemQuantity",
        "mutation"
      )
    },
    UpdateCartItemSize(
      variables: UpdateCartItemSizeMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateCartItemSizeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCartItemSizeMutation>(
            UpdateCartItemSizeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateCartItemSize",
        "mutation"
      )
    },
    ClearCart(
      variables: ClearCartMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ClearCartMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ClearCartMutation>(ClearCartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "ClearCart",
        "mutation"
      )
    },
    AddVoucher(
      variables: AddVoucherMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AddVoucherMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddVoucherMutation>(AddVoucherDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "AddVoucher",
        "mutation"
      )
    },
    RemoveVoucher(
      variables: RemoveVoucherMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RemoveVoucherMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveVoucherMutation>(
            RemoveVoucherDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "RemoveVoucher",
        "mutation"
      )
    },
    ValidateGiftCards(
      variables: ValidateGiftCardsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ValidateGiftCardsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ValidateGiftCardsMutation>(
            ValidateGiftCardsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ValidateGiftCards",
        "mutation"
      )
    },
    UpdateShippingCosts(
      variables: UpdateShippingCostsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateShippingCostsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateShippingCostsMutation>(
            UpdateShippingCostsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "UpdateShippingCosts",
        "mutation"
      )
    },
    Checkout(
      variables: CheckoutMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CheckoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckoutMutation>(CheckoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Checkout",
        "mutation"
      )
    },
    PaymentMethods(
      variables: PaymentMethodsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PaymentMethodsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaymentMethodsQuery>(
            PaymentMethodsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "PaymentMethods",
        "query"
      )
    },
    NewsletterSignup(
      variables: NewsletterSignupMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<NewsletterSignupMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NewsletterSignupMutation>(
            NewsletterSignupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "NewsletterSignup",
        "mutation"
      )
    },
    Payment(
      variables: PaymentMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PaymentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaymentMutation>(PaymentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Payment",
        "mutation"
      )
    },
    VerifyAdyenPayment(
      variables: VerifyAdyenPaymentQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<VerifyAdyenPaymentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerifyAdyenPaymentQuery>(
            VerifyAdyenPaymentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "VerifyAdyenPayment",
        "query"
      )
    },
    PaymentDetails(
      variables: PaymentDetailsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PaymentDetailsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaymentDetailsMutation>(
            PaymentDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "PaymentDetails",
        "mutation"
      )
    },
    PostalCodeLookup(
      variables: PostalCodeLookupQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PostalCodeLookupQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostalCodeLookupQuery>(
            PostalCodeLookupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "PostalCodeLookup",
        "query"
      )
    },
    AdyenCreateCheckoutSession(
      variables: AdyenCreateCheckoutSessionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AdyenCreateCheckoutSessionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AdyenCreateCheckoutSessionMutation>(
            AdyenCreateCheckoutSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "AdyenCreateCheckoutSession",
        "mutation"
      )
    },
    OrderVerifyFullyPaid(
      variables: OrderVerifyFullyPaidMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderVerifyFullyPaidMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderVerifyFullyPaidMutation>(
            OrderVerifyFullyPaidDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderVerifyFullyPaid",
        "mutation"
      )
    },
    OrderAddVoucher(
      variables: OrderAddVoucherMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderAddVoucherMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderAddVoucherMutation>(
            OrderAddVoucherDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderAddVoucher",
        "mutation"
      )
    },
    OrderAddGiftcard(
      variables: OrderAddGiftcardMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderAddGiftcardMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderAddGiftcardMutation>(
            OrderAddGiftcardDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderAddGiftcard",
        "mutation"
      )
    },
    OrderRemoveGiftcard(
      variables: OrderRemoveGiftcardMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderRemoveGiftcardMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderRemoveGiftcardMutation>(
            OrderRemoveGiftcardDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderRemoveGiftcard",
        "mutation"
      )
    },
    OrderRemoveVoucher(
      variables: OrderRemoveVoucherMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderRemoveVoucherMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderRemoveVoucherMutation>(
            OrderRemoveVoucherDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderRemoveVoucher",
        "mutation"
      )
    },
    ValidateGiftCard(
      variables: ValidateGiftCardMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ValidateGiftCardMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ValidateGiftCardMutation>(
            ValidateGiftCardDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ValidateGiftCard",
        "mutation"
      )
    },
    OrderByIdAndEmail(
      variables: OrderByIdAndEmailQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<OrderByIdAndEmailQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderByIdAndEmailQuery>(
            OrderByIdAndEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "OrderByIdAndEmail",
        "query"
      )
    },
    ReturnOrderRequest(
      variables: ReturnOrderRequestMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ReturnOrderRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReturnOrderRequestMutation>(
            ReturnOrderRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ReturnOrderRequest",
        "mutation"
      )
    },
    ChoicesWarrantySignup(
      variables: ChoicesWarrantySignupMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ChoicesWarrantySignupMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChoicesWarrantySignupMutation>(
            ChoicesWarrantySignupDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ChoicesWarrantySignup",
        "mutation"
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
